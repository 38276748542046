<template>
    <div>
        <div class="main_top">
            <span @click="getUrl" class="top_name">{{ $t('name_logo') }}</span>
            <div class="flex_1"></div>
            <div class="flex_right">
                <el-image class="lang_img" src="https://www.diit-operation-platform.com/demo/img/Vector.png"></el-image>
                <el-dropdown class="drop_top">
                    <span class="el-dropdown-link">
                        {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in langList" :key="item">
                            <div @click="langClick(item)">{{ item }}</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div class="line_top"></div>
                <!-- <div class="btn_top" v-if="!token" @click="openDialog">{{ $t('login') }}</div>
                <div class="btn_top" v-if="!token" @click="openEnterpriseRegistration">{{ $t('register') }}</div>
                <div class="btn_top" v-if="token" @click="exit">{{ $t('exit') }}</div>
                <div class="btn_flex" @click="download">
                    <el-image class="flex_top_logo" src="https://www.diit-operation-platform.com/demo/Group.png"></el-image>
                    <span>{{ $t('download') }}</span>
                </div> -->
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <div class="btn_flex">
                            <el-image class="flex_top_logo"
                                src="https://www.diit-operation-platform.com/demo/Group.png"></el-image>
                        </div>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="!token">
                            <div class="btn_top" @click="openDialog">{{ $t('login') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="!token">
                            <div class="btn_top" @click="openEnterpriseRegistration">{{ $t('register') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="token">
                            <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="btn_top" @click="download">{{ $t('download') }}</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>


            <el-dialog :title="$t('welcome')" :visible.sync="dialogVisible" v-if="dialogVisible" width="30%" :modal="true"
                z-index="9">
                <el-form ref="form" :model="form" label-width="60px" label-position="top" :rules="rules">
                    <el-form-item :label="$t('user_name')" prop="usercode">
                        <el-input v-model="form.usercode"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('pwd')" prop="pwd">
                        <el-input v-model="form.pwd" show-password></el-input>
                    </el-form-item>
                    <el-form-item class="right_item">
                        <el-button @click="dialogVisible = false" plain type="info">{{ $t('cancel') }}</el-button>
                        <el-button type="warning" @click="submitForm('form')">{{ $t('determine') }}</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
        <div class="h64"></div>



        <div class="right_kf" @click="openkf">
            <el-image src="https://www.diit-operation-platform.com/demo/img/kf.png" class="kf_img"></el-image>
            <div>{{ $t('support') }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            lang: '简体中文',
            // langList: ['简体中文', '日本語', 'Malay', 'English'],
            langList: ['简体中文', 'English'],
            dialogVisible: false,
            dialogVisible_kf: false,
            form: {
                usercode: '',
                pwd: ''
            },
            rules: {
                usercode: [
                    { required: true, message: '', trigger: 'input' }
                ],
                pwd: [
                    { required: true, message: '', trigger: 'input' }
                ],
            },
            token: ''
        }
    },
    mounted() {
        if (sessionStorage.getItem('rym_token')) {
            this.token = sessionStorage.getItem('rym_token')
        }
        if (this.$route.query.locale) {

            if (this.$route.query.locale == 'en') {
                this.$i18n.locale = 'en'
            } else {
                this.$i18n.locale = 'zh'
            }
        }
        this.lang = this.$i18n.locale == 'zh' ? '简体中文' : 'English'
    },
    methods: {
        langClick(item) {
            this.lang = item
            this.$i18n.locale = item == '简体中文' ? 'zh' : (item == '日本語' ? 'ja' : (item == 'Malay' ? 'ma' : 'en'))
        },
        openDialog() {
            this.form = {}
            this.dialogVisible = true
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const res = axios(this.getOptions({
                        ...this.form,
                        pwd: btoa(encodeURIComponent(this.form.pwd)),
                        apiId: 'login',
                        dbid: '01',
                        isCheckLogonRights: false
                    }))

                    res.then(response => {
                        if (response.data.id != -1) {
                            // this.$message.success(this.$t('login_succeeded'));
                            this.dialogVisible = false;
                            sessionStorage.setItem('rym_snkey', response.data.data.snkey);

                            const res2 = axios(this.getOptions({
                                usercode: this.form.usercode,
                                apiId: 'dlgclassrun',
                                dbid: '01',
                                btn: '{"cmd":"DLG","name":"查询Token","icon":"iconfont icon-bip-","size":"mini","bIconleft":true,"enable":true,"hasIcon":true,"type":"","hint":"","dlgType":"D","dlgCont":"inetbas.web.outsys.oauth.BipSysTokenServ*301;0;0","dlgSname":"","bdcell":"","etap":"","ftap":"","btnPosit":"2","sourcePage":"","visible":false}',
                            }))

                            res2.then(response => {
                                this.token = response.data.data.token
                                sessionStorage.setItem('rym_user', this.form.usercode);
                                sessionStorage.setItem('rym_token', this.token);
                                this.$router.push('/complete')
                            })

                        } else {
                            this.$message.error(response.data.message);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        exit() {
            this.$confirm(this.$t('log_out'), this.$t('please_confirm'), {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.token = ''
                sessionStorage.removeItem("rym_token");
                sessionStorage.removeItem("rym_snkey");
                sessionStorage.removeItem("rym_user");
                sessionStorage.removeItem("rym_scm");
                this.$router.push('/')
            }).catch(() => { });
        },


        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },

        download() {
            this.$alert(this.$t('soon'), '', {
                confirmButtonText: this.$t('determine'),
                showConfirmButton: false,
                callback: action => { }
            });
        },

        getUrl() {
            if (this.$route.path == '/details') {
                this.$router.push('/')
            }
        },

        openkf() {
            let user_code = sessionStorage.getItem('rym_scm')
            let url = this.$store.state.host + '/entrance/TouristIndex'
            if(user_code){
                url += "?user_code="+user_code
            }
            window.open(url)
        },

        openEnterpriseRegistration() {
            window.location.href = this.$store.state.host + '/entrance/EnterpriseRegistration?locale=' + (this.$i18n.locale == 'en' ? 'en' : 'zh-CN')
        }
    }

}
</script>

<style>
@import url(../assets/navigation.css);
</style>
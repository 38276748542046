
import Vue from 'vue';
import VueI18n from 'vue-i18n';
 
Vue.use(VueI18n);
 
const messages = {
  ja: require('./ja.json'),
  zh: require('./zh.json'),
  ma: require('./ma.json'),
  en: require('./en.json'),
};
 
const i18n = new VueI18n({
  locale: 'zh', // set default locale
  messages, // set locale messages
});
 
export default i18n;
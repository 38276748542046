<template>
 <div>
    <div class="flex_top">
            <el-image class="flex_top_logo" src="https://www.diit-operation-platform.com/diit/logo.png"></el-image>
            <div v-for="(item, index) in activeList" :key="index" @click="activeClick(item.url)"
                :class="(index == 0 && topIndex) ? 'flex_top_title flex_top_title_active' : 'flex_top_title'">

                <el-popover v-if="item.type" placement="bottom" width="200" trigger="click">
                    <div class="wx_img">
                        <el-image class="wx_logo" src="https://www.diit-operation-platform.com/diit/wx_down.png"></el-image>
                    </div>
                    <span slot="reference">{{ item.name }}</span>
                </el-popover>
                <span v-else>{{ item.name }}</span>
            </div>
            <div class="flex_top_right">
                <div :class="topIndex ? 'flex_top_right_title' : 'flex_top_right_title flex_top_title_active'" @click="register">注册</div>
                <div class="flex_top_right_title" @click="openDialog">登录</div>
            </div>
        </div>
        <el-dialog title="登录"  :visible.sync="dialogFormVisible" width="500px">
            <el-form :model="form" label-position="right" >
                <el-form-item label="用户名">
                    <el-input v-model="form.name" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="form.pass" placeholder="请输入密码" show-password></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogFormVisible = false">登录</el-button>
            </div>
        </el-dialog>
 </div>
</template>

<script>
export default {
    data() {
        return {
            dialogFormVisible: false,
            form: {
                name: '',
                pass: ''
            },
            activeList: [
                {
                    name: '首页',
                    url: '/'
                },
                {
                    name: '国际贸易操作平台',
                    url: 'https://www.diit-operation-platform.com/Tbtrade'
                },
                {
                    name: '数字交割仓',
                    url: 'https://www.diit-operation-platform.com/Gateway'
                },
                {
                    name: '任意CLUB',
                    url: 'https://www.diit-operation-platform.com:8002',
                    // type: 1
                },
                {
                    name: '星罗市场',
                    url: 'https://www.diit-operation-platform.com/LIDRP'
                }
            ],
        }
    },
    props: ['topIndex'],
    methods: {
        activeClick(url) {
            if (url == '/') {
                this.$router.push('/')
            } else {
                if (url) {
                    window.open(url)
                }
            }
        },
        openDialog() {
            this.form = {
                name: '',
                pass: ''
            }
            this.dialogFormVisible = true
        },
        register() {
            this.$router.push('/register')
            // let routeData = this.$router.resolve({ path: '/register'})
            // window.open(routeData.href, '_blank');
        }
    }
}
</script>

<style>
@import url(../assets/index.css);
</style>
<template>
  <div class="other_main">
        <div class="organization">
            <span>{{$t("角色管理")}}</span>
        </div>
        <div class="organization_line"></div>
        <div class="organization_flex">
            <div class="role_flex_left">
                <div class="organization_flex_left_top">
                    <div class="role_flex_left_top_icon"></div>
                    <div class="organization_flex_left_top_name">
                        <span>{{$t("区域列表")}}</span>
                    </div>
                </div>
                <el-tree :data="list1" node-key="id" default-expand-all :expand-on-click-node="false" @node-click="chooseCity">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span :style="{color: sysid == data.id ? '#006bfc' : ''}">{{ node.label }}</span>
                    </span>
                </el-tree>
            </div>
            <div class="role_flex_left w310">
                <div class="organization_flex_left_top">
                    <div class="role_flex_left_top_icon2"></div>
                    <div class="organization_flex_left_top_name">
                        <span>{{$t("角色列表")}}</span>
                        <div class="organization_flex_left_top_btn" @click="addClick">{{$t("新增角色")}}</div>
                    </div>
                </div>
                <el-tree :data="list2" node-key="id" default-expand-all :expand-on-click-node="false">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="custom-tree-node-span" @click="chooseRole(data)" :style="{color: gwcode == data.code ? '#006bfc' : ''}">
                            <span class="mr26">{{ data.code }}</span>
                            <span>{{ node.label }}</span>
                        </span>
                        <span>
                            <div class="tree_img2" @click="clickEdit(data)"></div>
                            <div class="tree_img3" @click="clickdel(data)"></div>
                        </span>
                    </span>
                </el-tree>
            </div>
            <div class="role_flex_right">
                <div class="role_flex_right_top">
                    <span>{{$t("添加角色对应权限")}}</span>
                    <div class="role_flex_right_top_btn" @click="sure">{{$t("保存")}}</div>
                </div>
                <div class="role_flex_right_choose">
                    <div class="role_flex_right_choose_flex" v-for="(item, index) in list3" :key="index">
                        <div class="role_flex_right_choose_flex_left">
                            <el-checkbox :label="item.name" :indeterminate="item.indeterminate" v-model="item.checkAll" @change="handleCheckAllChange($event, index)"></el-checkbox>
                        </div>
                        <div class="role_flex_right_choose_flex_right">
                            <el-checkbox-group v-model="item.checked" @change="handleCheckedCitiesChange($event, index)">
                                <el-checkbox v-for="(ite, ind) in item.list" :key="ind" :label="ite" class="role_flex_right_choose_flex_right_check"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :title="edit ? $t('编辑角色') : $t('新增角色')" :visible.sync="dialogVisible" width="465px">
            <div class="other_main_dig_flex" v-if="edit">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("角色编码")}}
                </div>
                <el-input placeholder="" v-model="from.id" :disabled="true" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("角色名称")}}
                </div>
                <el-input placeholder="" v-model="from.name" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    {{$t("角色说明")}}
                </div>
                <el-input placeholder="" v-model="from.sname" type="textarea" :rows="2" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_line"></div>
            <div class="other_main_dig_btn">
                <div class="other_main_dig_btn_sure" @click="clickSure">{{$t("确认")}}</div>
                <div class="other_main_dig_btn_cancle" @click="dialogVisible = false">{{$t("取消")}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return{
            list1: [],
            list2: [],
            list3: [],
            edit: false,
            dialogVisible: false,
            from: {
                id: 'GW0022',
                name: '',
                sname: '',
            },
            sysid: '',
            gwcode: ''
        }
    },
    props: ['countryList'],
    mounted() {
        this.list1 = JSON.parse(JSON.stringify(this.countryList))
        this.list1.forEach((r, index) => {
            r.label = r.name
            r.children.forEach(m => {
                m.label = m.name
            })
            // this.init(index)
        })
    },
    methods: {
        getRole() {
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取系统下岗位列表","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*207;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ "scm": sessionStorage.getItem('rym_scm'), "sysid": this.sysid })
            }))

            res.then(response => {
                if (response?.data?.data?.value) {
                    this.list2 = []
                    response?.data?.data?.value.forEach(r => {
                        r.id = r.sid
                        r.label = r.gwname
                        r.code = r.gwcode
                    })
                    this.list2 = JSON.parse(JSON.stringify(response?.data?.data?.value))
                }
            })
        },
        chooseCity(data) {
            this.sysid = data.id
            this.gwcode = ''
            this.getRole()
            this.getMenu()
        },
        chooseRole(data) {
            this.gwcode = data.code
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取岗位授权菜单","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*214;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ 
                    "sysid": this.sysid,
                    gwcode: [this.gwcode]
                })
            }))

            res.then(response => {
                this.list3.forEach(r => {
                    r.checked = []
                    r.indeterminate = false
                    r.checkAll = false
                    if (response.data.data.menus.includes(r.id)){
                        r.listId.forEach((m, index) => {
                            if (response.data.data.menus.includes(m)) {
                                r.checked.push(r.list[index])
                            }
                        })
                    }
                    if (r.checked.length == r.list.length) {
                        r.indeterminate = false
                        r.checkAll = true
                    } else {
                        if (r.checked.length != 0) {
                            r.indeterminate = true
                        }
                    }
                })
                this.list3 = JSON.parse(JSON.stringify(this.list3))
            })
        },
        getMenu() {
            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取系统对应菜单","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*209;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ "sysid": this.sysid })
            }))

            res2.then(response => {
                if (response?.data?.data?.menus) {
                    this.list3 = []
                    response?.data?.data?.menus.forEach(r => {
                        let arr = []
                        let arr2 = []
                        r.childMenu.forEach(m => {
                            arr.push(m.menuId)
                            arr2.push(m.menuName)
                        })
                        this.list3.push({
                            checkAll: false,
                            indeterminate: false,
                            checked: [],
                            name: r.menuName,
                            id: r.menuId,
                            listId: arr,
                            list: arr2
                        })
                    })
                }
            })
        },
        init(index) {
            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取区域","icon":"iconfont icon-bip-","size":"mini","bIconleft":true,"enable":true,"hasIcon":true,"type":"","hint":"","dlgType":"D","dlgCont":"rym.entrance.serv.SysInfoServ*201;0;0","dlgSname":"","bdcell":"","etap":"","ftap":"","btnPosit":"2","sourcePage":"","visible":false}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ "regionId": this.list1[index].sid, "snkey": sessionStorage.getItem('rym_snkey') })
            }))

            res2.then(response => {
                if (response.data.data.value.length) {
                    response.data.data.value.forEach(m => {
                        m.id = m.id
                        m.label = m.name
                    })
                    this.list1[index].children = []
                    this.list1[index].children = response.data.data.value
                }
                this.list1 = JSON.parse(JSON.stringify(this.list1))
            })
        },
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },
        handleCheckedCitiesChange(val, index) {
            let checkedCount = val.length;
            this.list3[index].checkAll = checkedCount === this.list3[index].list.length;
            this.list3[index].indeterminate = checkedCount > 0 && checkedCount < this.list3[index].list.length;
        },
        handleCheckAllChange(val, index) {
            this.list3[index].checked = val ? this.list3[index].list : [];
            this.list3[index].indeterminate = false;
        },
        addClick() {
            this.edit = false
            this.from = {
                name: '',
                sname: '',
            }
            this.dialogVisible = true
        },
        clickSure() {
            if (!this.from.name) {
                this.$message.error(this.$t('请输入角色名称'));
            } else {
                if (this.edit) {
                    const res2 = axios(this.getOptions({
                        apiId: 'dlgclassrun',
                        dbid: '01',
                        btn: '{"cmd":"DLG","name":"获取系统下岗位列表","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*208;0;0"}',
                        usercode: sessionStorage.getItem('rym_user'),
                        env: JSON.stringify({ 
                            scm: sessionStorage.getItem('rym_scm'),
                            sysid: this.sysid,
                            gwData: {
                                sid: this.from.sid,
                                gwname: this.from.name
                            }
                        })
                    }))

                    res2.then(response => {
                        this.getRole()
                        this.dialogVisible = false
                    })
                } else {
                    const res2 = axios(this.getOptions({
                        apiId: 'dlgclassrun',
                        dbid: '01',
                        btn: '{"cmd":"DLG","name":"获取系统下岗位列表","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*208;0;0"}',
                        usercode: sessionStorage.getItem('rym_user'),
                        env: JSON.stringify({ 
                            scm: sessionStorage.getItem('rym_scm'),
                            sysid: this.sysid,
                            gwData: {
                                gwname: this.from.name
                            }
                        })
                    }))

                    res2.then(response => {
                        this.getRole()
                        this.dialogVisible = false
                    })
                }
            }
        },
        clickEdit(data) {
            this.from = {
                id: data.code,
                name: data.label,
                sname: '',
                sid: data.sid
            }
            this.edit = true
            this.dialogVisible = true
        },
        clickdel(data) {
            this.$confirm(this.$t('是否继续删除'), ' ', {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('取消'),
                type: 'warning'
            }).then(() => {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"获取系统下岗位列表","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*208;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({ 
                        scm: sessionStorage.getItem('rym_scm'),
                        isDel: true,
                        sysid: this.sysid,
                        gwData: {
                            sid: data.id,
                            gwname: data.name
                        }
                    })
                }))
                res.then(response => {
                    this.getRole()
                    this.dialogVisible = false
                })
            }).catch(() => {});
        },
        sure() {
            if (!this.gwcode) {
                this.$message.error(this.$t('请选择角色'));
                return
            }
            let arr = []
            this.list3.forEach(m => {
                if (m.checked.length) {
                    arr.push(m.id)
                    m.list.forEach((r, index) => {
                        if (m.checked.includes(r)) {
                            arr.push(m.listId[index])
                        }
                    })
                }
                
            })
            let obj = {
                scm: sessionStorage.getItem('rym_scm'),
                isgw: 1,
                sysid: this.sysid,
                auth: {}
            }
            obj.auth[this.gwcode] = arr
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"设置岗位权限","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*210;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify(obj)
            }))
            res.then(response => {
                this.$message.success(this.$t('操作成功'));
            })
        }
    }
}
</script>


<style>
@import url(../assets/role.css);
</style>
<template>
    <div style="min-width:1400px">
        <div class="main_bg"
            :style="{ backgroundImage: homeIndex == 0 ? ('url(' + require('../assets/img/482x.png') + ')') : ('url(' + require('../assets/img/' + homeIndex + '.png') + ')'), height: homeIndex == 0 ? '100vh' : '454px', minHeight: homeIndex == 0 ? '900px' : '454px', position: homeIndex == 0 ? '' : 'relative' }">
            <div class="main_bg_left"
                :style="{ backgroundImage: homeIndex == 0 ? ('url(' + require('../assets/img/82x.png') + ')') : '' }">
                <div class="main_bg_top">
                    <span class="main_bg_top_title">{{ $t('name_logo') }}</span>
                    <div class="main_bg_top_right">
                        <div class="main_bg_top_right_home_1" @click="changeIndex(0)">
                            {{ $t('首页') }}
                        </div>
                        <el-dropdown placement="bottom-start" trigger="click">
                            <span class="main_bg_top_drop_1">
                                {{ $t('应用服务') }}
                                <div class="main_bg_top_drop_img"></div>
                                <div v-if="homeIndex != 0" class="main_bg_top_right_home_img_1"
                                    :style="{ left: $i18n.locale == 'zh' ? '24px' : '36px' }"></div>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(1)">{{ $t('btob') }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(2)">{{ $t('warehouse') }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(3)">{{ $t('club') }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(4)">{{ $t('xingluo') }}</div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <span><a href="#about">{{ $t("联系我们") }}</a></span>
                    </div>
                </div>
            </div>
            <div class="main_bg_right">
                <div class="main_bg_right_lang">
                    <span
                        :class="$i18n.locale == 'zh' ? 'main_bg_right_lang_z main_bg_right_lang_active' : 'main_bg_right_lang_z'"
                        @click="langClick('zh')">{{$t('中')}}</span>
                    <span
                        :class="$i18n.locale == 'zh' ? 'main_bg_right_lang_e' : 'main_bg_right_lang_e main_bg_right_lang_active'"
                        @click="langClick('en')">EN</span>
                </div>
                <div class="main_bg_right_img" @click="changeIndex(0)"></div>

                <!-- <el-dropdown>
                    <span class="el-dropdown-link">
                        <div class="main_bg_right_img"></div>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="!token">
                            <div class="btn_top" @click="openDialog">{{ $t('login') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="!token">
                            <div class="btn_top" @click="openEnterpriseRegistration">{{ $t('register') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="token">
                            <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="btn_top" @click="download">{{ $t('download') }}</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
            <div class="main_center">
                <div class="main_center_bg">
                    <div v-if="homeIndex == 1">
                        <div>
                            <div class="main_center_img1"></div>
                            <div class="main_center_span1">{{ $t('btob') }}</div>
                        </div>
                        <div class="main_center_desc1">{{ $t('details_desc') }}</div>
                    </div>
                    <div v-if="homeIndex == 2">
                        <div>
                            <div class="main_center_img1 main_center_img2"></div>
                            <div class="main_center_span1">{{ $t('warehouse') }}</div>
                        </div>
                        <div class="main_center_desc1">{{ $t('warehouse_desc') }}</div>
                    </div>
                    <div v-if="homeIndex == 3">
                        <div>
                            <div class="main_center_img1 main_center_img3"></div>
                            <div class="main_center_span1">{{ $t('club') }}</div>
                        </div>
                        <div class="main_center_desc1">{{ $t('club_desc') }}</div>
                    </div>
                    <div v-if="homeIndex == 4">
                        <div>
                            <div class="main_center_img1 main_center_img4"></div>
                            <div class="main_center_span1">{{ $t('xingluo') }}</div>
                        </div>
                        <div class="main_center_desc1">{{ $t('xingluo_desc') }}</div>
                    </div>
                </div>
                <div class="main_bg_btn mt32" @click="openEnterpriseRegistration">
                    {{ $t('立即体验') }}
                    <div class="main_bg_btn_icon"></div>
                </div>
                <div class="main_bg_btn_inst" @click="openPDF(homeIndex)">
                    {{ $t('instruction') }}
                    <div class="main_flex_btn_icon"></div>
                </div>
            </div>
        </div>
        <div class="main_con" :style="{ height: homeIndex < 3 ? '446px' : '372px' }">
            <div v-if="homeIndex == 1">
                <div class="main_con_place1">
                    <div class="main_con_place1_img1"></div>
                    <div class="main_con_place1_title">{{ $t('service_object') }}</div>
                    <div :class="$i18n.locale == 'zh' ? 'main_con_place1_desc' : 'main_con_place1_desc plr74'">{{
                        $t('service_object_value') }}</div>
                </div>
                <div class="main_con_place1">
                    <div class="main_con_place1_img2"></div>
                    <div class="main_con_place1_title">{{ $t('service_content') }}</div>
                    <div class="main_con_place1_desc plr74">{{ $t('service_content_value') }}</div>
                </div>
                <div class="main_con_place1">
                    <div class="main_con_place1_img3"></div>
                    <div class="main_con_place1_title">{{ $t('service_effectiveness') }}</div>
                    <div class="main_con_place1_desc plr74">{{ $t('service_effectiveness_value') }}</div>
                </div>
            </div>
            <div v-if="homeIndex == 2">
                <div class="main_con_place1">
                    <div class="main_con_place1_img4"></div>
                    <div class="main_con_place1_title mt18">{{ $t('service_object2') }}</div>
                    <div class="main_con_place1_desc plr54">{{ $t('service_object_value2') }}</div>
                </div>
                <div class="main_con_place1">
                    <div class="main_con_place1_img5"></div>
                    <div class="main_con_place1_title mt18">{{ $t('service_content2') }}</div>
                    <div :class="$i18n.locale == 'zh' ? 'main_con_place1_desc plr84' : 'main_con_place1_desc plr44'">{{
                        $t('service_content_value2') }}</div>
                </div>
                <div class="main_con_place1">
                    <div class="main_con_place1_img6"></div>
                    <div class="main_con_place1_title mt18">{{ $t('service_effectiveness2') }}</div>
                    <div :class="$i18n.locale == 'zh' ? 'main_con_place1_desc plr44' : 'main_con_place1_desc plr5'">{{
                        $t('service_effectiveness_value2') }}</div>
                </div>
            </div>
            <div v-if="homeIndex == 3">
                <div class="main_con_place2">
                    <div class="main_con_place1_img7"></div>
                    <div class="main_con_place1_title mt18">{{ $t('三单对碰') }}</div>
                </div>
                <div class="main_con_place2">
                    <div class="main_con_place1_img8"></div>
                    <div class="main_con_place1_title mt18">{{ $t('信息同步') }}</div>
                </div>
                <div class="main_con_place2">
                    <div class="main_con_place1_img9"></div>
                    <div class="main_con_place1_title mt18">{{ $t('商品出库') }}</div>
                </div>
            </div>
            <div v-if="homeIndex == 4">
                <div class="main_con_place3">
                    <div class="main_con_place1_img10"></div>
                    <div class="main_con_place1_title mt18">{{ $t('service_object4') }}</div>
                </div>
                <div class="main_con_place3">
                    <div class="main_con_place1_img11"></div>
                    <div class="main_con_place1_title mt18">{{ $t('service_content4') }}</div>
                </div>
            </div>
        </div>
        <div class="details">
            <div class="details_title" v-if="homeIndex == 1">{{ $t('一站式操作平台解决方案') }}</div>
            <div class="details_title" v-if="homeIndex == 2">{{ $t('数字化仓储服务平台') }}</div>
            <div class="details_title" v-if="homeIndex == 3">{{ $t('用户平台') }}</div>
            <div class="details_title" v-if="homeIndex == 4">{{ $t('场景介绍') }}</div>
            <div v-if="homeIndex == 1">
                <div class="details_flex">
                    <div :class="detailFlexIndex == 1 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 1">{{ $t("全球统一身份ID认证") }}</div>
                    <div :class="detailFlexIndex == 2 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 2">{{ $t("智能化操作") }}</div>
                    <div :class="detailFlexIndex == 3 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 3">{{ $t("角色权限任务流程化") }}</div>
                </div>
                <div class="details_flex mt100" v-if="detailFlexIndex == 1">
                    <div class="details_flex_left">
                        <div class="details_flex_img1"></div>
                    </div>
                    <div class="textLeft">
                        <div class="details_flex_right_title">{{ $t("全球统一身份ID认证") }}</div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('身份选择') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('提交企业注册信息') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('选择服务器区域') }}</div>
                        </div>
                    </div>
                </div>
                <div class="details_flex mt100" v-if="detailFlexIndex == 2">
                    <div class="details_flex_left">
                        <div class="details_flex_img2"></div>
                    </div>
                    <div class="textLeft">
                        <div class="details_flex_right_title">{{ $t("智能化操作") }}</div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('提供全链条数字流程管理') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('统一语言标准流程') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('实际业务操作需求') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('国际贸易不再复杂') }}</div>
                        </div>
                    </div>
                </div>
                <div class="details_flex mt100" v-if="detailFlexIndex == 3">
                    <div class="details_flex_left">
                        <div class="details_flex_img3"></div>
                    </div>
                    <div class="textLeft">
                        <div class="details_flex_right_title">{{ $t("角色权限任务流程化") }}</div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('一键分配') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('统一化管理') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="homeIndex == 2">
                <div class="details_flex">
                    <div :class="detailFlexIndex == 1 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 1">{{ $t("现代化仓储设施") }}</div>
                    <div :class="detailFlexIndex == 2 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 2">{{ $t("AI检测服务") }}</div>
                </div>
                <div class="details_flex mt100" v-if="detailFlexIndex == 1">
                    <div class="details_flex_left">
                        <div class="details_flex_img4"></div>
                    </div>
                    <div class="textLeft">
                        <div class="details_flex_right_title">{{ $t("现代化仓储设施") }}</div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('能够实现货物信息的精准采集和实时监控') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('对货物的位置') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('自动安排存放位置') }}</div>
                        </div>
                    </div>
                </div>
                <div class="details_flex mt100" v-if="detailFlexIndex == 2">
                    <div class="details_flex_left">
                        <div class="details_flex_img5"></div>
                    </div>
                    <div class="textLeft">
                        <div class="details_flex_right_title">{{ $t("AI检测服务") }}</div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('结合世界领先') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('可将原料样本') }}</div>
                        </div>
                        <div class="details_flex_right">
                            <div class="details_flex_icon"></div>
                            <div class="details_flex_span">{{ $t('货物样品清晰化') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="homeIndex == 3">
                <div class="details_flex">
                    <div :class="detailFlexIndex == 1 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 1">{{ $t("service_object_title1") }}</div>
                    <div :class="detailFlexIndex == 2 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 2">{{ $t("service_object_title2") }}</div>
                </div>
                <div class="mt64">
                    <div class="mt64_flex" v-if="detailFlexIndex == 1">
                        <div class="mt64_flex_1">
                            <div class="mt64_flex_title">{{ $t("service_object_title1") }}</div>
                            <div class="m12">{{ $t("service_object_title3") }}</div>
                            <div class="pr100">{{ $t('中国市场为小程序应') }}</div>
                            <div class="mt64_flex_btn" @click="imgShow = true">
                                {{ $t('扫描添加小程序') }}
                                <div class="mt64_flex_btn_icon"></div>
                            </div>
                            <div class="mt64_flex mt80">
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">500</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('跨境商品数量') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">64</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('注册会员数量') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">12</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('场景客户数量') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt64_flex_img"></div>
                    </div>
                    <div class="mt64_flex" v-if="detailFlexIndex == 2">
                        <div class="mt64_flex_1">
                            <div class="mt64_flex_title">{{ $t("service_object_title2") }}</div>
                            <div class="m12">{{ $t("service_object_title4") }}</div>
                            <div class="pr100">{{ $t('马来市场为APP应用') }}</div>
                            <div class="pr100 blue">{{ $t('对接日本三大购物平台') }}</div>
                            <div>
                                <div class="mt64_flex_place1">
                                    <div class="mt64_flex_place1_img"></div>
                                    <div class="mt64_flex_place1_name">Mercari</div>
                                </div>
                                <div class="mt64_flex_place2">
                                    <div class="mt64_flex_place1_img2"></div>
                                    <div class="mt64_flex_place1_name">Rakuten</div>
                                </div>
                                <div class="mt64_flex_place3">
                                    <div class="mt64_flex_place1_img3"></div>
                                    <div class="mt64_flex_place1_name">Rakuten Rakuma</div>
                                </div>
                            </div>
                            <div class="mt64_flex_btn mt16" @click="imgShow2 = true">
                                {{ $t('下载应用') }}
                                <div class="mt64_flex_btn_icon2"></div>
                            </div>
                            <div class="mt64_flex mt16">
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">1208</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('跨境商品数量') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">54</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('注册会员数量') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">22</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('场景客户数量') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt64_flex_img2"></div>
                    </div>
                </div>
            </div>
            <div v-if="homeIndex == 4">
                <div class="details_flex">
                    <div :class="detailFlexIndex == 1 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 1">{{ $t("选品平台") }}</div>
                    <div :class="detailFlexIndex == 2 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 2">{{ $t("沟通合作") }}</div>
                    <div :class="detailFlexIndex == 3 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 3">{{ $t("积分实景化") }}</div>
                    <div :class="detailFlexIndex == 4 ? 'details_flex_1 details_flex_active' : 'details_flex_1'"
                        @click="detailFlexIndex = 4">{{ $t("积分本地引流") }}</div>
                </div>
                <div class="mt64">
                    <div class="mt64_flex" v-if="detailFlexIndex == 1">
                        <div class="mt64_flex_1">
                            <div class="mt64_flex_title">{{ $t("选品平台") }}</div>
                            <div class="m12">{{ $t("了解不同国家和地区") }}</div>
                            <div class="mt64_flex mt80">
                                <div class="mt64_flex1_m200">
                                    <div>
                                        <span class="mt64_flex1_price">1202</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('跨境商品数量') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">781</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('平台月访问量') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt64_flex_img3"></div>
                    </div>
                    <div class="mt64_flex" v-if="detailFlexIndex == 2">
                        <div class="mt64_flex_1">
                            <div class="mt64_flex_title">{{ $t("沟通合作") }}</div>
                            <div class="m12">{{ $t("需求匹配") }}</div>
                            <div class="mt64_flex mt80">
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">12</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('已达成合作数量') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt64_flex_img4"></div>
                    </div>
                    <div class="mt64_flex" v-if="detailFlexIndex == 3">
                        <div class="mt64_flex_1">
                            <div class="mt64_flex_title">{{ $t("积分实景化") }}</div>
                            <div class="m12">{{ $t("激励企业") }}</div>
                            <div class="mt64_flex mt80">
                                <div class="mt64_flex1_m200">
                                    <div>
                                        <span class="mt64_flex1_price">5261</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('实景商家') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">79</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('可积分消费商品') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt64_flex_img5"></div>
                    </div>
                    <div class="mt64_flex" v-if="detailFlexIndex == 4">
                        <div class="mt64_flex_1">
                            <div class="mt64_flex_title">{{ $t("积分本地引流") }}</div>
                            <div class="m12">{{ $t("本地的商家") }}</div>
                            <div class="mt64_flex mt80">
                                <div class="mt64_flex1_m200">
                                    <div>
                                        <span class="mt64_flex1_price">64</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('场景客户数量') }}</div>
                                </div>
                                <div class="mt64_flex1">
                                    <div>
                                        <span class="mt64_flex1_price">2191</span>
                                        <span class="mt64_flex1_price_w">{{$t('万')}}</span>
                                    </div>
                                    <div class="mt64_flex1_desc">{{ $t('积分量') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt64_flex_img6"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="homeIndex == 3" class="details_footer">
            <div class="details_footer_title">{{ $t('跨境俱乐部') }}</div>
            <div>
                <div class="details_footer_place">
                    <div class="details_footer_place_img"></div> 
                    <div class="details_footer_place_title">{{ $t('积分奖励') }}</div>
                    <div class="details_footer_place_desc">{{ $t('C端用户') }}</div> 
                </div>
                <div class="details_footer_place">
                    <div class="details_footer_place_img2"></div> 
                    <div class="details_footer_place_title">{{ $t('积分兑换') }}</div>
                    <div class="details_footer_place_desc">{{ $t('积分可以累积') }}</div> 
                </div>
                <div class="details_footer_place">
                    <div class="details_footer_place_img3"></div> 
                    <div class="details_footer_place_title">{{ $t('会员等级制') }}</div>
                    <div class="details_footer_place_desc">{{ $t('激励会员') }}</div> 
                </div>
                <div class="details_footer_place">
                    <div class="details_footer_place_img4"></div> 
                    <div class="details_footer_place_title">{{ $t('积分引流') }}</div>
                    <div class="details_footer_place_desc">{{ $t('积分实景化更贴合') }}</div> 
                </div>
            </div>
        </div>
        <div class="main_footer_blue">
            <div class="main_footer_blue_title" v-if="homeIndex == 1">{{ $t('一站式服务') }}</div>
            <div class="main_footer_blue_title" v-if="homeIndex == 2">{{ $t('实时监控') }}</div>
            <div class="main_footer_blue_title" v-if="homeIndex == 3">{{ $t('连接世界贸易') }}</div>
            <div class="main_footer_blue_title" v-if="homeIndex == 4">{{ $t('汇聚无限可能') }}</div>
            <div class="main_bg_btn w180" @click="openEnterpriseRegistration">
                {{ $t('立即体验') }}
                <div class="main_bg_btn_icon"></div>
            </div>
        </div>
        <div class="main_footer">
            <div style="display:flex">
                <div style="flex:1">
                   <div style="display:flex">
                    <div class="main_footer_1">
                        <div class="main_footer_1_img">
                            <span class="main_footer_1_title">{{ $t('instruction') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" @click="openPDF(1)">{{ $t('btob') }}</div>
                        <div class="main_footer_1_footer_con" @click="openPDF(2)">{{ $t('warehouse') }}</div>
                        <div class="main_footer_1_footer_con" @click="openPDF(3)">{{ $t('club') }}</div>
                        <div class="main_footer_1_footer_con" @click="openPDF(4)">{{ $t('xingluo') }}</div>
                    </div>
                    <div class="main_footer_2">
                        <div class="main_footer_2_img">
                            <span class="main_footer_1_title">{{ $t('help') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" @click="download">{{ $t('download2') }}</div>
                        <div class="main_footer_1_footer_con" @click="openkf">{{ $t('support') }}</div>
                    </div>
                    <div class="main_footer_3">
                        <div class="main_footer_3_img">
                            <span class="main_footer_1_title">{{ $t('legalSupport') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" @click="legal($t('serviceTerms'))">{{ $t('serviceTerms') }}
                        </div>
                        <div class="main_footer_1_footer_con" @click="legal($t('serviceTerms2'))">{{ $t('serviceTerms2') }}
                        </div>
                    </div>
                    <div style="flex:1"><div class="main_footer_4_line"></div></div>
                   </div>
                </div>
                <div>

                    <div class="main_footer_4" id="about">
                        <div class="main_footer_4_img">
                            <span class="main_footer_1_title">{{ $t('联系我们') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" style="width: 335px">{{ $t('地址') }}</div>
                        <div class="main_footer_1_footer_con">{{ $t('邮箱') }}
                            <a class="support_span" href="mailto:support@diit-operation-platform.com">support@diit-operation-platform.com</a>
                            </div>
                        <div class="main_footer_1_footer_con">{{ $t('电话') }}+603-2181 8555</div>
                    </div>
                </div>
            </div>
            <div class="main_footer_line"></div>
            <div class="main_footer_ver">©2023-2024 Zhong Yun Supply Chain Management Pte Ltd 版权所有</div>
        </div>
        
        <el-dialog
        :visible.sync="imgShow"
        width="300px">
        <el-image :src="require('../assets/img/439172662732.jpg')" style="width: 100%;heigth:100%"></el-image>
        </el-dialog>
        <el-dialog
        :visible.sync="imgShow2"
        width="300px">
        <el-image :src="require('../assets/img/21.jpg')" style="width: 100%;heigth:100%"></el-image>
        </el-dialog>
    </div>
</template>

<script>
import Top from '../component/navigation.vue'
import Footer from '../component/footer.vue'
export default {
    name: "Index",
    components: {
        Top,
        Footer
    },
    mounted() {
        this.homeIndex = this.$route.query.index
        window.scrollTo({
            top: 0,
            // behavior: 'smooth'
        });
    },
    data() {
        return {
            detailFlexIndex: 1,
            imgList: [
                {
                    zh: 'https://www.diit-operation-platform.com/demo/img/banner.jpg',
                    en: 'https://www.diit-operation-platform.com/demo/img/banner_en.jpg'
                },
                {
                    zh: 'https://www.diit-operation-platform.com/demo/img/banner2.jpg',
                    en: 'https://www.diit-operation-platform.com/demo/img/banner2_en.jpg'
                }
            ],
            dialogVisible: false,
            imgShow: false,
            imgShow2: false,
            dialogVisible_kf: false,
            form: {
                usercode: '',
                pwd: ''
            },
            rules: {
                usercode: [
                    { required: true, message: '', trigger: 'input' }
                ],
                pwd: [
                    { required: true, message: '', trigger: 'input' }
                ],
            },
            token: '',
            type: 1,
            homeIndex: 0
        }
    },
    methods: {
        changeIndex(index) {
            if (index) {
                if (index != this.homeIndex) {
                    this.$router.push('/details?index=' + index)
                }
            } else {
                this.$router.push('/')
            }
        },
        download() {
            // this.$alert(this.$t('soon'), '', {
            //     confirmButtonText: this.$t('determine'),
            //     showConfirmButton: false,
            //     callback: action => { }
            // });
            this.$message({
                message: this.$t('soon'),
                type: 'warning'
            });
        },
        openkf() {
            let user_code = sessionStorage.getItem('rym_scm')
            let url = this.$store.state.host + '/entrance/TouristIndex'
            if(user_code){
                url += "?user_code="+user_code
            }
            window.open(url)
        },
        legal(name) {
            window.open(`https://www.diit-operation-platform.com/demo/doc/${name}.pdf`)
        },
        openPDF(index) {
            window.open(`https://www.diit-operation-platform.com/demo/doc/instruction${index}.pdf`)
        },
        getUrl(index) {
            this.$router.push('/details?index=' + index)
        },
        langClick(item) {
            this.$i18n.locale = item
        },
        openDialog() {
            this.form = {}
            this.dialogVisible = true
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const res = axios(this.getOptions({
                        ...this.form,
                        pwd: btoa(encodeURIComponent(this.form.pwd)),
                        apiId: 'login',
                        dbid: '01',
                        isCheckLogonRights: false
                    }))

                    res.then(response => {
                        if (response.data.id != -1) {
                            // this.$message.success(this.$t('login_succeeded'));
                            this.dialogVisible = false;
                            sessionStorage.setItem('rym_snkey', response.data.data.snkey);

                            const res2 = axios(this.getOptions({
                                usercode: this.form.usercode,
                                apiId: 'dlgclassrun',
                                dbid: '01',
                                btn: '{"cmd":"DLG","name":"查询Token","icon":"iconfont icon-bip-","size":"mini","bIconleft":true,"enable":true,"hasIcon":true,"type":"","hint":"","dlgType":"D","dlgCont":"inetbas.web.outsys.oauth.BipSysTokenServ*301;0;0","dlgSname":"","bdcell":"","etap":"","ftap":"","btnPosit":"2","sourcePage":"","visible":false}',
                            }))

                            res2.then(response => {
                                this.token = response.data.data.token
                                sessionStorage.setItem('rym_user', this.form.usercode);
                                sessionStorage.setItem('rym_token', this.token);
                                this.$router.push('/complete')
                            })

                        } else {
                            this.$message.error(response.data.message);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        exit() {
            this.$confirm(this.$t('log_out'), this.$t('please_confirm'), {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.token = ''
                sessionStorage.removeItem("rym_token");
                sessionStorage.removeItem("rym_snkey");
                sessionStorage.removeItem("rym_user");
                sessionStorage.removeItem("rym_scm");
                this.$router.push('/')
            }).catch(() => { });
        },


        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },

        openEnterpriseRegistration() {
            window.location.href = this.$store.state.host + '/entrance/EnterpriseRegistrationNew?locale=' + (this.$i18n.locale == 'en' ? 'en' : 'zh-CN')
        }
    }
}
</script>


<style>@import url(../assets/index_new.css);</style>
<template>
    <div class="other_main">

        <div class="organization">
            <span>{{ $t('发布列表') }}</span>
            <div class="organization_back" @click="back">
                <div class="organization_back_icon"></div>
                {{ $t("返回") }}
            </div>
        </div>
        <div class="organization_line"></div>
        <div class="publish_main">
            <div class="publish_main_flex">
                <div :class="active ? 'publish_main_flex_left' : 'publish_main_flex_left publish_main_flex_active'"
                    @click="stateClick(0)">
                    {{ $t('全部发布') }}
                    <span class="publish_main_flex_left_num">
                        {{ statisData.total }}
                    </span>
                </div>
                <div class="publish_main_flex_right">
                    <div @click="stateClick(1)" :class="active == 1 ? 'publish_main_flex_right_active' : ''">
                        {{ $t('发布中')}}
                        <span class="publish_main_flex_left_num">
                            {{ statisData.release_num }}
                        </span>
                    </div>
                    <div class="publish_main_flex_right_line"></div>
                    <div @click="stateClick(2)" :class="active == 2 ? 'publish_main_flex_right_active' : ''">
                        {{ $t('草稿箱') }}
                        <span class="publish_main_flex_left_num">
                            {{ statisData.draft_num }}
                        </span>
                    </div>
                    <div class="publish_main_flex_right_line"></div>
                    <div @click="stateClick(3)" :class="active == 3 ? 'publish_main_flex_right_active' : ''">
                        {{ $t('已删除') }}
                        <span class="publish_main_flex_left_num">
                            {{ statisData.delete_num }}
                        </span>
                    </div>
                </div>
            </div>
            <!-- 买家需求列表  -->
            <template v-if="isBuy">
                <div class="publish_main_table">
                    <div class="publish_main_table_1" style="text-align:center">{{ $t('需求') }}</div>
                    <div class="publish_main_table_2">{{ $t('货物信息') }}</div>
                    <div class="publish_main_table_1">{{ $t('数量1') }}</div>
                    <div class="publish_main_table_1">{{ $t('状态') }}</div>
                    <div class="publish_main_table_1">{{ $t('操作') }}</div>
                </div>
                <div class="publish_main_table_col">
                    <div v-for="(item, index) in list" :key="index" class="publish_main_row">
                        <div class="publish_main_row_1">
                            <span>{{ $t('系统编号') }}：</span>
                            <span class="publish_main_row_1_val">{{ item.sid }}</span>
                            <span>{{ $t('需求编号') }}：</span>
                            <span class="publish_main_row_1_val">{{ item.demand_code }}</span>
                            <span>{{ $t('发布时间') }}：</span>
                            <span class="publish_main_row_1_val">{{ item.mkdate }}</span>
                            <template v-if="item.is_delete ==0">
                                <div v-if="item.isopen == 1" class="publish_main_row_1_1">
                                    {{  $t('发布中') }}
                                </div>
                                <div v-else class="publish_main_row_1_2">
                                    {{  $t('草稿箱') }}
                                </div>
                            </template>
                        </div>
                        <div class="publish_main_row_2">
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_2_right">
                                    <div>{{ item.goods_name }}</div>
                                    <div class="publish_main_table_2_right_center" v-if="item.goods_pname_name">
                                        <span class="publish_main_table_2_right_gray">{{ $t('品名') }}</span>
                                        <span>{{ item.goods_pname_name }}</span>
                                    </div>
                                    <div class="publish_main_table_2_right_center">
                                        <span class="publish_main_table_2_right_gray">{{ $t('品种') }}</span>
                                        <span>{{ item.brand_id_name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_2_right_center" v-if="item.source_area_name">
                                    <span class="publish_main_table_2_right_gray">{{ $t('产地') }}</span>
                                    <span>{{ item.source_area_name }}</span>
                                </div>
                                <div class="publish_main_table_2_right_center" v-if="item.source_city">
                                    <span class="publish_main_table_2_right_gray">{{ $t('产区') }}</span>
                                    <span>{{ item.source_city }}</span>
                                </div>
                                <div class="publish_main_table_2_right_center" v-if="item.goods_level">
                                    <span class="publish_main_table_2_right_gray">{{ $t('等级') }}</span>
                                    <span>{{ item.goods_level }}</span>
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_2_right_center" v-if="item.spc_desc">
                                    <span class="publish_main_table_2_right_gray">{{ $t('规格') }}</span>
                                    <br />
                                    <span>{{ item.spc_desc }}</span>
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div>
                                    {{ item.d_numb_des || 0 }}
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div>
                                    {{ item.state == 0 ? $t('审核中') : item.state == 6 ? $t('审批通过') :  $t('已驳回')  }}
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_1_link" @click="goBack(item.sid)">{{ $t('查看详情') }}</div>
                                <template v-if="item.is_delete == 0">
                                    <div class="publish_main_table_1_link" style="margin-top:12px" @click="deleteData(item.sid,1)">{{ $t('删除') }}</div>
                                    <template v-if="item.isopen == 1">
                                        <div class="publish_main_table_1_link" style="margin-top:12px" @click="releaseData(item.sid,0)">{{ $t('下架') }}</div>
                                    </template>
                                    <template v-else>
                                        <div class="publish_main_table_1_link" style="margin-top:12px" @click="releaseData(item.sid,1)">{{ $t('上架') }}</div>
                                    </template>
                                </template>
                                <template v-if="item.is_delete == 1">
                                    <div class="publish_main_table_1_link" style="margin-top:12px" @click="deleteData(item.sid,0)">{{ $t('撤销删除') }}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="publish_main_table">
                    <div class="publish_main_table_1" style="text-align:center">{{ $t('商品') }}</div>
                    <div class="publish_main_table_2">{{ $t('标签') }}</div>
                    <div class="publish_main_table_1">{{ $t('单价/包装') }}</div>
                    <div class="publish_main_table_1">{{ $t('数量1') }}</div>
                    <div class="publish_main_table_1">{{ $t('状态') }}</div>
                    <div class="publish_main_table_1">{{ $t('操作') }}</div>
                </div>

                <div class="publish_main_table_col">
                    <div v-for="(item, index) in list" :key="index" class="publish_main_row">
                        <div class="publish_main_row_1">
                            <span>{{ $t('系统编号') }}：</span>
                            <span class="publish_main_row_1_val">{{ item.sid }}</span>
                            <span>{{ $t('商品编号') }}：</span>
                            <span class="publish_main_row_1_val">{{ item.goods_id }}</span>
                            <span>{{ $t('发布时间') }}：</span>
                            <span class="publish_main_row_1_val">{{ item.mkdate }}</span>
                            <template v-if="item.is_delete ==0">
                                <div v-if="item.is_open == 1" class="publish_main_row_1_1">
                                    {{  $t('发布中') }}
                                </div>
                                <div v-else class="publish_main_row_1_2">
                                    {{  $t('草稿箱') }}
                                </div>
                            </template>
                        </div>
                        <div class="publish_main_row_2">
                            <div class="publish_main_table_1" style="display: flex;">
                                <div>
                                    <el-image v-if="item.imglist.length > 0"
                                        :src="$store.state.host + '/entrance' + item.imglist[0].fullpath"
                                        style="width: 68px;height:68px;"></el-image>
                                </div>
                                <div class="publish_main_table_2_right">
                                    <div>{{ item.goods_name }}</div>
                                    <div class="publish_main_table_2_right_center" v-if="item.name_name">
                                        <span class="publish_main_table_2_right_gray">{{ $t('品名') }}</span>
                                        <span>{{ item.name_name }}</span>
                                    </div>
                                    <div class="publish_main_table_2_right_center">
                                        <span class="publish_main_table_2_right_gray">{{ $t('品种') }}</span>
                                        <span>{{ item.brand_id_name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_2_right_center" v-if="item.glevel">
                                    <span class="publish_main_table_2_right_gray">{{ $t('等级') }}</span>
                                    <span>{{ item.glevel }}</span>
                                </div>
                                <div class="publish_main_table_2_right_center" v-if="item.pkgspc_name">
                                    <span class="publish_main_table_2_right_gray">{{ $t('包装规格') }}：</span>
                                    <span>{{ item.pkgspc_name }}</span>
                                </div>
                                <div class="publish_main_table_2_right_center" v-if="item.source_area_name">
                                    <span class="publish_main_table_2_right_gray">{{ $t('产地产区') }}</span>
                                    <span>{{ item.source_area_name }}
                                        <span v-if="item.source_city ">
                                            - {{ item.source_city }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_2_right_center" v-if="item.glevel">
                                    <span class="publish_main_table_2_right_gray">{{ $t('体积') }}：</span>
                                    <span>{{ item.volume }}</span>
                                </div>
                                <div class="publish_main_table_2_right_center" v-if="item.glevel">
                                    <span class="publish_main_table_2_right_gray">{{ $t('数量') }}：</span>
                                    <span>{{ item.quantity }}</span>
                                </div>
                                <div class="publish_main_table_2_right_center" v-if="item.savemethod">
                                    <span class="publish_main_table_2_right_gray">{{ $t('存储方式') }}：</span>
                                    <span>{{ item.savemethod }} </span>
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div v-if="item.pkgtype_name">
                                    <span class="publish_main_table_2_right_gray">{{ $t('包装') }}：</span>
                                    <span>{{ item.pkgtype_name }}</span>
                                </div>
                                <!-- 跨境 -->
                                <template v-if="item.d_category == 2 && item.spclist && item.spclist.length>0">
                                    <div class="publish_main_table_2_right_center">
                                        <span class="publish_main_table_2_right_gray">{{ $t('规格') }}</span>
                                        <span class="publish_main_table_2_right_unit">{{ item.spclist[0].spc }}</span>
                                    </div>
                                    <div>
                                        <span class="publish_main_table_2_right_gray">{{ $t('单价') }}：</span>
                                        <span class="publish_main_table_2_right_unit">{{ item.spclist[0].price }}</span>
                                    </div>
                                </template>
                                <!-- 大宗 -->
                                <template v-else-if="item.d_category == 1">
                                    <div class="publish_main_table_2_right_center">
                                        <span class="publish_main_table_2_right_gray">{{ $t('规格') }}</span>
                                        <span class="publish_main_table_2_right_unit">{{ item.spc_desc }}</span>
                                    </div>
                                    <div>
                                        <span class="publish_main_table_2_right_gray">{{ $t('单价') }}：</span>
                                        <span class="publish_main_table_2_right_unit">{{ item.price }}</span>
                                    </div>
                                </template>
                                
                            </div>
                            <div class="publish_main_table_1">
                                <!-- 跨境 -->
                                <template v-if="item.d_category == 2 && item.spclist && item.spclist.length>0">
                                    {{ item.spclist[0].qty }}
                                </template>
                                <!-- 大宗 -->
                                <template v-else-if="item.d_category == 1">
                                    {{ item.quantity || 0 }}
                                </template>
                            </div>
                            
                            <div class="publish_main_table_1">
                                <div>
                                    {{ item.state == 0 ? $t('审核中') : item.state == 6 ? $t('审批通过') :  $t('已驳回') }}
                                </div>
                            </div>
                            <div class="publish_main_table_1">
                                <div class="publish_main_table_1_link" @click="goBack(item.sid)">{{ $t('查看详情') }}</div>
                                <template v-if="item.is_delete == 0">
                                    <div class="publish_main_table_1_link" style="margin-top:12px" @click="deleteData(item.sid,1)">{{ $t('删除') }}</div>
                                    <template v-if="item.is_open == 1">
                                        <div class="publish_main_table_1_link" style="margin-top:12px" @click="releaseData(item.sid,0)">{{ $t('下架') }}</div>
                                    </template>
                                    <template v-else>
                                        <div class="publish_main_table_1_link" style="margin-top:12px" @click="releaseData(item.sid,1)">{{ $t('上架') }}</div>
                                    </template>
                                </template>
                                <template v-if="item.is_delete == 1">
                                    <div class="publish_main_table_1_link" style="margin-top:12px" @click="deleteData(item.sid,0)">{{ $t('撤销删除') }}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div style="text-align: end;margin-top: 10px;">
                <el-pagination
                    @current-change="init"
                    :current-page="pageInfo.page"
                    layout="prev, pager, next"
                    :total="pageInfo.total">
                </el-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            active: 0,
            list: [],
            isBuy: false,
            statisData: {
                delete_num: 0,
                draft_num: 0,
                release_num: 0,
                total: 0,
            },
            pageInfo:{
                page: 1,
                total: 0
            }
        }
    },
    mounted() {
        this.isBuy = sessionStorage.getItem('rym_is_buyer') == 'true'
        this.init(1);
    },
    methods: {
        back() {
            this.$emit('handBack')
        },
        goBack(sid) {
            this.$emit('goBack', sid)
        },
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },
        init(page) {
            if(page)
            this.pageInfo.page = page
            // 买家查看本公司需求列表
            if (this.isBuy) {
                let condition = { }
                if(this.active == 1 ){
                    condition.is_open = 1;
                }else if(this.active==2){
                    condition.is_open = 0;
                }else if(this.active ==3){
                    condition.is_delete = 1;
                }
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"需求列表（本公司）","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*201;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        snkey: sessionStorage.getItem('rym_snkey'),
                        page: {
                            currPage: this.pageInfo.page,
                            pageSize: 10
                        },
                        condition: condition
                    })
                }))
                res2.then(response => {
                    this.list = response?.data?.data?.values
                    this.pageInfo.total = response?.data?.data?.page?.total
                })
            } else {
                //卖家查看本公司商品列表
                let condition = { }
                if(this.active == 1 ){
                    condition.is_open = 1;
                }else if(this.active==2){
                    condition.is_open = 0;
                }else if(this.active ==3){
                    condition.is_delete = 1;
                }
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"商品列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*201;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        snkey: sessionStorage.getItem('rym_snkey'),
                        page: {
                            currPage: this.pageInfo.page,
                            pageSize: 10
                        },
                        condition: condition
                    })
                }))

                res2.then(response => {
                    this.list = response?.data?.data?.values
                    this.pageInfo.total = response?.data?.data?.page?.total
                })
            }
            this.getStatData();
        },
        /**
         * 数量统计
         */
        getStatData() {
            //买家查看 本公司需求数据
            if (this.isBuy) {
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"需求统计","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*207;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                }))
                res2.then(res => {
                    if (res.data.id == 0) {
                        this.statisData = res.data.data
                    }
                })
            } else {
                //卖家查看本公司商品数据
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"商品详情","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*255;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                }))
                res2.then(res => {
                    if (res.data.id == 0) {
                        this.statisData = res.data.data
                    }
                })
            }
        },
        stateClick(vl){
            this.active = vl;
            this.init(1);
        },
        /**
         * 
         * @param sid   系统编号
         * @param type  删除/恢复
         */
        deleteData(sid,type){
            if (this.isBuy) {//需求
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"需求删除/恢复","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*205;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({"sid":sid,"is_delete":type})
                }))
                res2.then(res => {
                    if (res.data.id == 0) {
                        this.init(null);
                    }
                })
            }else{//商品
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"商品删除或者恢复","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*210;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({"sid":sid,"is_delete":type})
                }))
                res2.then(res => {
                    if (res.data.id == 0) {
                        this.init(null);
                    }
                })
            }
        },
        /**
         * 
         * @param sid   系统编号
         * @param type  上架/下架
         */
        releaseData(sid,type){
            if (this.isBuy) {//需求
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"需求上/下架","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*206;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({"sid":sid,"is_open":type})
                }))
                res2.then(res => {
                    if (res.data.id == 0) {
                        this.init();
                    }
                })
            }else{//商品
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"商品详情","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*215;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({"sid":sid,"is_open":type})
                }))
                res2.then(res => {
                    if (res.data.id == 0) {
                        this.init();
                    }
                })
            }
        }
    }
}
</script>


<style scoped>
.publish_main {
    padding: 20px;
    overflow: hidden;
    height: calc(100% - 100px);
}

.publish_main_flex {
    display: flex;
    margin-bottom: 20px;
}

.publish_main_flex_left {
    padding: 6px 10px;
    background: #f7f8fa;
    border-radius: 4px;
    color: #333333;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
}

.publish_main_flex_left_num {
    margin-left: 7px;
    color: #999999;
}

.publish_main_flex_active {
    background: #e8f2ff;
    color: #006bfc;
    font-weight: 500;
}

.publish_main_flex_active span,
.publish_main_flex_right_active span {
    color: #006bfc;
}

.publish_main_flex_right_active {
    color: #006bfc;
    font-weight: 500;
}

.publish_main_flex_right {
    padding: 6px 10px;
    background: #f7f8fa;
    border-radius: 4px;
    color: #333333;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
}

.publish_main_flex_right>div {
    cursor: pointer;
}

.publish_main_flex_right_line {
    width: 1px;
    height: 12px;
    background: rgba(203, 207, 216, 1);
    margin: 0 12px;
}

.publish_main_table {
    height: 48px;
    line-height: 48px;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    font-weight: 500;
    color: #333333;
    font-size: 14px;
}

.publish_main_table_2 {
    flex: 2;
    color: #333333;
    font-size: 14px;
}

.publish_main_table_1 {
    flex: 1;
    color: #333333;
    font-size: 14px;
}

.publish_main_row {
    padding-top: 16px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(239, 239, 239, 1);
}

.publish_main_row_1 {
    color: #999999;
    font-size: 14px;
}

.publish_main_row_1_val {
    margin-right: 54px;
}

.publish_main_row_1_1 {
    padding: 1px 10px;
    display: inline-block;
    background: #e8f2ff;
    border-radius: 2px;
    color: #006bfc;
    font-size: 12px;
}

.publish_main_row_1_2 {
    padding: 1px 10px;
    display: inline-block;
    background: rgba(232, 255, 240, 1);
    border-radius: 2px;
    color: rgba(0, 137, 79, 1);
    font-size: 12px;
}

.publish_main_row_2 {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.publish_main_table_2_right {
    margin-left: 16px;
}

.publish_main_table_2_right_gray {
    color: #666666;
}

.publish_main_table_2_right_center {
    margin: 4px 0;
}

.publish_main_table_2_right_unit {
    color: #999999;
    font-size: 12px;
    margin-left: 4px;
}

.publish_main_table_1_link {
    color: #006bfc;
    font-size: 14px;
    cursor: pointer;
}

.publish_main_table_col {
    overflow-y: auto;
    height: calc(100% - 140px);
}

.publish_main_table_col::-webkit-scrollbar {
    display: none;
}
</style>
<template>
    <div class="agreement_main">
        <div class="agreement_main_1">
            <div class="agreement_main_1_title">{{ $t('协议列表') }}</div>
            <div v-for="(item, index) in list" :key="index">
                <div class="agreement_main_1_place">
                    <div class="agreement_main_1_place_1">
                        <span class="agreement_main_1_place_1_name">{{ $t('协议名称') }}：</span>
                        <!-- <div class="agreement_main_1_place_1_btn">{{ $t('《跨境电商平台服务协议》') }}</div> -->
                        <div class="agreement_main_1_place_1_btn">{{ item.protocol_name || '-' }}</div>
                        <span class="agreement_main_1_place_1_name_link" @click="handlePreview(item.protocol_file)">{{ $t('下载并签署') }}</span>
                    </div>
                    <div class="agreement_main_1_place_2" @click="changeShow(index)">
                        <el-image :src="item.show ? require('../assets/img/1171@2x.png') : require('../assets/img/1172@2x.png')"
                            style="width: 12px;height:12px;display: inline-block;"></el-image>
                    </div>
                </div>
                <div v-if="item.show" class="agreement_main_1_wj">
                    <div>
                        <el-upload class="upload-demo" drag :action="`#${index}`" :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :http-request="upload"
                            :limit="1">
                            <div style="position: relative;top: 50px;">
                                <el-image :src="require('../assets/img/328@2x.png')"
                                    style="width: 60px;height:62px;display: inline-block;"></el-image>
                                <div
                                    style="display: inline-block;vertical-align: top;position: relative;top: 20px;left: 20px;">
                                    <div style="color:#333333;font-size:14px;">{{ $t("点击或将文件拖动到这里上传") }}</div>
                                    <div style="color:#999;font-size:12px;text-align: left;">{{ $t('大小10M') }}</div>
                                </div>
                            </div>
                        </el-upload>
                    </div>
                    <div v-if="item.protocol_sign_name" style="padding:20px 0;font-size:14px;cursor: pointer;" @click="handlePreview(item.protocol_sign_file)">{{ item.protocol_sign_name }}</div>
                </div>
            </div>
            <div v-for="(item, index) in payList" :key="index">
                <div class="agreement_main_1_je">
                    <div class="agreement_main_1_title">{{ $t('支付保证金') }}</div>
                    <div class="agreement_main_1_je_desc">
                        <div class="agreement_main_1_je_desc_title">{{ $t('支付方式') }}</div>
                        <div class="agreement_main_1_je_desc_flex">
                            <div class="agreement_main_1_je_desc_flex_1">
                                <el-radio v-model="item.pay_type" :label="1" size="medium">
                                    <el-image :src="require('../assets/img/692@2x.png')"
                                        style="width: 24px;height:24px;display: inline-block;margin: 0 10px;position: relative;top: 7px;"></el-image>
                                    <span
                                        style="font-weight:500;font-size:14px;color:#333333!important;">{{ $t("支付宝支付") }}</span>
                                </el-radio>
                            </div>
                            <div style="width: 20px;"></div>
                            <div class="agreement_main_1_je_desc_flex_1">
                                <el-radio v-model="item.pay_type" :label="2" size="medium">
                                    <el-image :src="require('../assets/img/1188@2x.png')"
                                        style="width: 24px;height:24px;display: inline-block;margin: 0 10px;position: relative;top: 7px;"></el-image>
                                    <span
                                        style="font-weight:500;font-size:14px;color:#333333!important;">{{ $t("微信支付") }}</span>
                                </el-radio>
                            </div>
                            <div style="width: 20px;"></div>
                            <div class="agreement_main_1_je_desc_flex_1">
                                <el-radio v-model="item.pay_type" :label="3" size="medium">
                                    <el-image :src="require('../assets/img/1111@2x.png')"
                                        style="width: 24px;height:24px;display: inline-block;margin: 0 10px;position: relative;top: 7px;"></el-image>
                                    <span
                                        style="font-weight:500;font-size:14px;color:#333333!important;">{{ $t("银行卡支付") }}</span>
                                </el-radio>
                            </div>
                        </div>
                        <div class="agreement_main_1_je_desc_foot">
                            <span style="color:#333333;font-weight:500;font-size:14px;">{{ $t('需付金额') }}：</span>
                            <span style="color:#333333;font-weight:700;font-size:18px;">¥ {{item.tb_amount}}</span>
                            <div v-if="item.s_state!=1" class="agreement_main_1_je_desc_foot_btn" @click="saveMargin(item)">{{ $t('立即支付') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="agreement_main_2"></div>
        <div class="agreement_main_3">
            <div class="agreement_main_3_place">
                <div class="agreement_main_3_place_title">{{ $t('帮助和服务') }}</div>
                <div class="agreement_main_3_place_flex">
                    <div class="agreement_main_3_place_flex_1"></div>
                    <div class="agreement_main_3_place_flex_2">{{ $t('帮助中心') }}</div>
                    <div class="agreement_main_3_place_flex_3">{{ $t('进入') }}</div>
                </div>
                <div class="agreement_main_3_place_que">{{ $t('如何设置') }}</div>
                <div class="agreement_main_3_place_desc">{{ $t('您可以通过') }}</div>
                <div class="agreement_main_3_place_flex">
                    <div class="agreement_main_3_place_flex_1"></div>
                    <div class="agreement_main_3_place_flex_2">{{ $t('客服邮箱') }}</div>
                </div>
                <div class="agreement_main_3_place_desc">
                    <a href="mailto:support@diit-operation-platform.com">support@diit-operation-platform.com</a>
                </div>
                <div class="agreement_main_3_place_btn">
                    <a href="mailto:support@diit-operation-platform.com">{{ $t('联系我们') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            list: [],
            payList:[]
        }
    },
    mounted() {
        this.init(0)
    },
    methods: {
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },
        //查看
        handlePreview(url) {
            window.open(this.$store.state.host + '/entrance' + url)
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 10;

            if (!isLt2M) {
                this.$message.error(this.$t('大小10M'));
            }
            return isLt2M;
        },
        //上传
        upload(file) {

            let index = Number(file.action.split('#')[1])

            let formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 添加文件到formData

            axios({
                method: 'post',
                url: this.$store.state.host + '/entrance/sysupd?updid=33&dbid=201&fjkey=PROTOCOL&snkey=' + sessionStorage.getItem('rym_snkey'),
                data: formData,
                responseType: 'json',
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为multipart/form-data类型
            })
                .then((response) => {
                    this.list[index].protocol_sign_file = `/sysupd?snkey=${sessionStorage.getItem('rym_snkey')}&fjroot=${response.data.data.fj_root}&updid=36&fjname=${response.data.data.fname}`
                    this.list[index].protocol_sign_name = response.data.data.fname
                    // this.$message.success(this.$t('上传成功'));

                    const res2 = axios(this.getOptions({
                        apiId: 'dlgclassrun',
                        dbid: '01',
                        btn: '{"cmd":"DLG","name":"协议签署","dlgType":"D","dlgCont":"rym.entrance.serv.TBProtocol*201;0;0"}',
                        usercode: sessionStorage.getItem('rym_user'),
                        env: JSON.stringify({ 
                            p_sid: this.list[index].p_sid,
                            s_sid: this.list[index].s_sid,
                            fj_root: response.data.data.fj_root,
                            fj_name: response.data.data.fname
                         })
                    }))

                    res2.then(response => {
                        this.init(index)
                        this.$message.success(this.$t('上传成功'));
                    })
                })
                .catch((error) => {
                    console.error(error); // 处理错误信息
                });
        },
        getMgnList(){
          const res2 = axios(this.getOptions({
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"保证金列表","dlgType":"D","dlgCont":"rym.entrance.serv.TBProtocol*203;0;0"}',
            usercode: sessionStorage.getItem('rym_user'),
            env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey') })
          }))

          res2.then(response => {
            this.payList = response?.data?.data?.values
            this.payList.forEach((r) => {
              if(r.pay_type){
                r.pay_type=parseInt(r.pay_type)
              }else {
                r.pay_type = 1
              }
            })
            this.payList = JSON.parse(JSON.stringify(this.payList))
            console.log(this.payList,'payList')
          })

        },
      saveMargin(item){
        console.log(item,'item')
        const res2 = axios(this.getOptions({
          apiId: 'dlgclassrun',
          dbid: '01',
          btn: '{"cmd":"DLG","name":"立即支付","dlgType":"D","dlgCont":"rym.entrance.serv.TBProtocol*204;0;0"}',
          usercode: sessionStorage.getItem('rym_user'),
          env: JSON.stringify({
            p_sid: item.p_sid,
            s_sid: item.s_sid,
            pay_type: item.pay_type
          })
        }))

        res2.then(response => {
          this.payList = response?.data?.data?.values
          if(response?.data?.data?.s_sid){
            this.$message.success( this.$t('支付成功'));
            this.getMgnList()
          }else {
            this.$message.error( this.$t('serverAbnormal'));
          }
        })
      },
        init(i) {
            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"协议列表","dlgType":"D","dlgCont":"rym.entrance.serv.TBProtocol*200;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey') })
            }))

            res2.then(response => {
                this.list = response?.data?.data?.values
                this.list.forEach((r, index) => {
                    r.radio = 1
                    if (index == i) {
                        r.show = true
                    } else {
                        r.show = false
                    }
                })
                this.list = JSON.parse(JSON.stringify(this.list))
            })
          this.getMgnList()
        },
        changeShow(index) {
            this.list[index].show = !this.list[index].show
            this.list = JSON.parse(JSON.stringify(this.list))
        }
    }

}
</script>

<style>
.agreement_main .el-upload,
.agreement_main .el-upload-dragger {
    width: 100%;
}
</style>

<style scoped>
.agreement_main {
    display: flex;
    height: 100%;
}

.agreement_main_1 {
    flex: 1;
}

.agreement_main_2 {
    width: 20px;
    background: #f5f5f8;
}

.agreement_main_3 {
    width: 220px;
    background: #f5f5f8;
}

.agreement_main_3_place {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px 18px;
}

.agreement_main_3_place_title {
    font-weight: 500;
    color: #333333;
    font-size: 14px;
    margin-bottom: 33px;
}

.agreement_main_3_place_flex {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}

.agreement_main_3_place_flex_1 {
    width: 2px;
    height: 10px;
    background: #006bfc;
    border-radius: 0px 6px 6px 0px;
    margin-right: 6px;
}

.agreement_main_3_place_flex_2 {
    font-weight: 500;
    color: #333333;
    font-size: 14px;
    flex: 1;
}

.agreement_main_3_place_flex_3 {
    color: #006bfc;
    font-size: 14px;
}

.agreement_main_3_place_que {
    color: #333333;
    font-size: 14px;
    margin-bottom: 6px;
}

.agreement_main_3_place_desc {
    color: #666666;
    font-size: 14px;
    margin-bottom: 18px;
}

.agreement_main_3_place_btn {
    height: 36px;
    background: #f6f8fd;
    border: 1px solid;
    border-color: #dddfe7;
    border-radius: 3px;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
    color: #333333;
    font-size: 14px;
    cursor: pointer;
}

.agreement_main_1_title {
    padding: 20px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);
}

.agreement_main_1_place {
    border-radius: 4px;
    display: flex;
    background: #f6f8fd;
    margin: 20px;
    margin-bottom: 0;
}

.agreement_main_1_place_2 {
    width: 31px;
    height: 68px;
    background: #eceff7;
    border-radius: 0px 4px 0px 0px;
    text-align: center;
    line-height: 68px;
    cursor: pointer;
}

.agreement_main_1_place_1 {
    flex: 1;
    /* line-height: 68px;
    height:68px; */
    padding-left: 20px;
}

.agreement_main_1_place_1_name {
    color: #333333;
    font-size: 14px;
}

.agreement_main_1_place_1_btn {
    background: #ffffff;
    border: 1px solid;
    border-color: #dee4ed;
    border-radius: 4px;
    padding: 10px 11px;
    display: inline-block;
    margin-left: 10px;
    font-weight: 500;
    color: #333333;
    font-size: 14px;
    margin-top: 14px;
    margin-right: 20px;
}

.agreement_main_1_place_1_name_link {
    color: #006bfc;
    font-size: 14px;
}

.agreement_main_1_wj {
    background: #f6f8fd;
    padding: 20px;
    border-top: rgba(234, 237, 245, 1) 1px solid;
    margin: 20px;
    margin-top: 0;
}

.agreement_main_1_je {
    /* background: #fcfdff; */
    /* border: 1px solid; */
    /* border-color: #dee4ed; */
    border-radius: 4px;
    margin-top: 20px;
}

.agreement_main_1_je_title {
    padding: 18px 20px;
    font-weight: 500;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);
}

.agreement_main_1_je_desc {
    padding: 18px 20px;
}

.agreement_main_1_je_desc_title {
    padding-bottom: 14px;
    color: #333333;
    font-size: 14px;
}

.agreement_main_1_je_desc_flex {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.agreement_main_1_je_desc_flex_1 {
    flex: 1;
    height: 68px;
    background: #ffffff;
    border: 1px solid;
    border-color: #dddfe7;
    border-radius: 3px;
    line-height: 68px;
    padding-left: 20px;
}

.agreement_main_1_je_desc_foot {
    background: #eff2f8;
    border: 1px solid;
    border-color: #dee4ed;
    border-radius: 0px 0px 4px 4px;
    padding: 20px;
}

.agreement_main_1_je_desc_foot_btn {
    cursor: pointer;
    float: right;
    width: 117px;
    height: 32px;
    background: #006bfc;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    color: #ffffff;
    font-size: 14px;
}</style>
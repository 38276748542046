import Vue from 'vue'
import Router from 'vue-router'

import Index from "@/pages/index.vue";
import Register from "@/pages/register.vue";
import Complete from "@/pages/completeNew.vue";
import Details from "@/pages/detailsNew.vue";


export default new Router({
  routes: [
    {
      path: '/', // 首页
      name: 'Index',
      component: Index
    },
    {
      path: '/complete', // 登录成功
      name: 'Complete',
      component: Complete
    },
    {
      path: '/details', // 详情
      name: 'Details',
      component: Details
    },
    {
      path: '/register', // 注册
      name: 'Register',
      component: Register
    }
  ],
  mode: 'history'
})

Vue.use(Router)
<template>
  <div class="agreement_main">
    <div class="agreement_main_1">
      <div class="agreement_main_1_title">
        {{
          dialogObj1.val1 == 1 ? isBuy ? $t('大宗货需求发布') : $t('大宗货物发布') : isBuy ? $t('跨境商品需求发布') : $t('跨境商品发布')
        }}
        <div class="organization_back" @click="back">
          <div class="organization_back_icon"></div>
          {{ $t("返回") }}
        </div>
      </div>
      <div class="goos_main_over">
        <div style="display:flex;align-items: center;padding: 20px 40px;">
          <div style="font-weight:500;color:#333333;font-size:14px;margin-right:16px">{{$t("当前选择")}}</div>
          <div class="show_dialog_flex_1">
                        <span style="font-weight:500;font-size:14px;color:#333333;">{{
                            dialogObj1.val1 == 1 ? $t("大宗货物")
                                :
                                $t("跨境商品")
                          }}</span>
            <el-image v-if="dialogObj1.val1 == 1" :src="require('../assets/img/950@2x.png')"
                      style="width: 46px;height:40px;position: absolute;right: 0;top: 0;"></el-image>
            <el-image v-else :src="require('../assets/img/35@2x1.png')"
                      style="width: 46px;height:40px;position: absolute;right: 0;top: 0;"></el-image>
          </div>
          <div v-if="!isBuy && dialogObj1.val1 == 2" class="show_dialog_flex_1">
                        <span style="font-weight:500;font-size:14px;color:#333333;">{{
                            dialogObj1.val2 == 1 ? $t("B端商家")
                                :
                                $t("直面消费者")
                          }}</span>
            <el-image v-if="dialogObj1.val2 == 1" :src="require('../assets/img/950@2x.png')"
                      style="width: 46px;height:40px;position: absolute;right: 0;top: 0;"></el-image>
            <el-image v-else :src="require('../assets/img/35@2x1.png')"
                      style="width: 46px;height:40px;position: absolute;right: 0;top: 0;"></el-image>
          </div>
          <div style="color:#666666;font-size:14px;margin-right:10px">{{$t("是否需要重新选择")}}</div>
          <div style="color:#006bfc;font-size:14px;cursor: pointer;" @click="chooseBack">{{$t("重选选择")}}</div>
        </div>
        <div
            style="display:flex;align-items: center;padding: 12px 0;background:#f6f8fd;border-radius:4px;margin: 0 20px;">
          <div
              style="width:2px;height:10px;background:#006bfc;border-radius:0px 6px 6px 0px;margin-right: 18px;">
          </div>
          <div style="font-weight:500;color:#333333;font-size:16px;">{{$t("基本信息")}}</div>
        </div>

        <div v-if="dialogObj1.val1 == 1">
          <div v-if="isBuy">
            <div style="padding: 20px 40px">
              <div style="display:flex;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("系统编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" disabled v-model="demandFrom.sid"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("需求编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="demandFrom.demand_code"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("货物名称")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="demandFrom.goods_name"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("货物分类")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.d_classify" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in classify" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("品名")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.goods_pname" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in productName" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("品种")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.brand_id" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in variety" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("等级")}}</div>
                  <el-input placeholder="" v-model="demandFrom.goods_level"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("处理方式")}}</div>
                  <el-input placeholder="" v-model="demandFrom.goods_process"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("产地")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.source_area" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in origin" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("产区")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="demandFrom.source_city"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isBuy">
            <div style="padding: 20px 40px">
              <div style="display:flex;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("系统编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" disabled v-model="commodityFrom.sid"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("商品编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="commodityFrom.goods_id"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("货物名称")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="commodityFrom.goods_name"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("货物分类")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.goods_category" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in classify" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("品名")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.name" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in productName" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("品种")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.brand_id" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in variety" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("等级")}}</div>
                  <el-input placeholder="" v-model="commodityFrom.glevel"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("处理方式")}}</div>
                  <el-input placeholder="" v-model="commodityFrom.hmethod"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("产地")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.source_area" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in origin" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("产区")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="commodityFrom.source_city"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div v-if="dialogObj1.val1 == 2">
          <div v-if="isBuy">
            <div style="padding: 20px 40px">
              <div style="display:flex;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("系统编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" disabled v-model="demandFrom.sid"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("需求编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="demandFrom.demand_code"
                            style="margin-top:4px"></el-input>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("商品名称")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="demandFrom.goods_name"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("商品分类")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.d_classify" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in classify" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("品种")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.brand_id" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in brand" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("原产地")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="demandFrom.source_area" placeholder=""
                             style="margin-top:4px;width: 100%;" filterable>
                    <el-option v-for="item in origin" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isBuy">
            <div style="padding: 20px 40px">
              <div style="display:flex;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("系统编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" disabled v-model="commodityFrom.sid"
                            style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("商品编号")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="commodityFrom.goods_id" style="margin-top:4px"></el-input>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("商品名称")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-input placeholder="" v-model="commodityFrom.goods_name" style="margin-top:4px"></el-input>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("商品分类")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.goods_category" placeholder="" style="margin-top:4px;width: 100%;"
                             filterable>
                    <el-option v-for="item in classify" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("品种")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.brand_id" placeholder="" style="margin-top:4px;width: 100%;"
                             filterable>
                    <el-option v-for="item in brand" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
                <div style="flex:1">
                  <div style="color:#333333;font-size:14px;">{{$t("原产地")}}<span
                      style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                  <el-select v-model="commodityFrom.source_area" placeholder="" style="margin-top:4px;width: 100%;"
                             filterable>
                    <el-option v-for="item in origin" :key="item.sid" :label="item.name"
                               :value="item.sid">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display:flex;margin-top: 16px;" v-if="!isBuy">
                <div style="flex:1;margin-right: 20px;">
                  <div style="color:#333333;font-size:14px;">{{$t("商品简介")}}</div>
                  <el-input placeholder="" v-model="commodityFrom.pc_remark" style="margin-top:4px"
                            type="textarea"></el-input>
                </div>
                <div style="flex:1"></div>
              </div>
            </div>
          </div>
        </div>

        <div
            style="display:flex;align-items: center;padding: 12px 0;background:#f6f8fd;border-radius:4px;margin: 0 20px;">
          <div
              style="width:2px;height:10px;background:#006bfc;border-radius:0px 6px 6px 0px;margin-right: 18px;">
          </div>
          <div style="font-weight:500;color:#333333;font-size:16px;">{{
              dialogObj1.val1 == 1 ? $t("货物信息") : $t("商品信息")
            }}
          </div>
          <div style="flex:1;padding-right:20px">
            <div class="right_place" v-if="!isBuy && dialogObj1.val1 == 2" @click="add">+</div>
          </div>
        </div>
        <div v-if="isBuy" style="padding: 20px 40px">
          <div style="color:#333333;font-size:14px;">{{$t("规格")}}</div>
          <el-input placeholder="" v-model="demandFrom.spc_desc" style="margin-top:4px"
                    type="textarea"></el-input>
        </div>
        <div v-else style="padding: 0 20px;padding-top: 20px">
          <div v-if="dialogObj1.val1 == 1" style="padding: 0 20px;padding-bottom: 20px;">
            <div style="display:flex;">
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("重量")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="commodityFrom.rough_weight"
                          style="margin-top:4px"></el-input>
              </div>
              <div style="flex:1">
                <div style="color:#333333;font-size:14px;">{{$t("体积")}}</div>
                <el-input placeholder="" v-model="commodityFrom.volume"
                          style="margin-top:4px"></el-input>
              </div>
            </div>
            <div style="display:flex;margin-top: 16px;">
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("包装类型")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-select v-model="commodityFrom.pkgtype" placeholder=""
                           style="margin-top:4px;width: 100%;" filterable>
                  <el-option v-for="item in packagingType" :key="item.sid" :label="item.name"
                             :value="item.sid">
                  </el-option>
                </el-select>
              </div>
              <div style="flex:1">
                <div style="color:#333333;font-size:14px;">{{$t("包装规格")}}</div>
                <el-select v-model="commodityFrom.pkgspc" placeholder=""
                           style="margin-top:4px;width: 100%;" filterable>
                  <el-option v-for="item in wrapSpecification" :key="item.sid" :label="item.name"
                             :value="item.sid">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div style="display:flex;margin-top: 16px;">
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("单价")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="commodityFrom.price"
                          style="margin-top:4px"></el-input>
              </div>
              <div style="flex:1" >
                <div style="color:#333333;font-size:14px;">{{$t("数量")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="commodityFrom.quantity"
                          style="margin-top:4px"></el-input>
              </div>
            </div>
            <div style="display:flex;margin-top: 16px;">
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("生产日期")}}</div>
                <el-date-picker v-model="commodityFrom.proddate" type="date" placeholder=""
                                style="margin-top:4px;width: 100%;">
                </el-date-picker>
              </div>
              <div style="flex:1">
                <div style="color:#333333;font-size:14px;">{{$t("保质期至")}}</div>
                <el-date-picker v-model="commodityFrom.enddate" type="date" placeholder=""
                                style="margin-top:4px;width: 100%;">
                </el-date-picker>
              </div>
            </div>
            <div style="margin-top: 16px;">
              <div style="color:#333333;font-size:14px;">{{$t("规格")}}</div>
              <el-input placeholder="" v-model="commodityFrom.spc_desc" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
            <div style="display:flex;margin-top: 16px;">
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("贮存方法")}}</div>
                <el-input placeholder="" v-model="commodityFrom.savemethod"
                          style="margin-top:4px"></el-input>
              </div>
              <div style="flex:1"></div>
            </div>
          </div>
          <div v-else>
            <div v-for="(item, index) in commodityFrom.spclist" :key="index"
                 style="padding: 10px 20px;background:#f7f8fa;border-radius:4px;margin-bottom: 20px;display: flex;position: relative;">
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("分类名称")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="item.type_name" style="margin-top:4px"></el-input>
              </div>
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("规格")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="item.spc" style="margin-top:4px"></el-input>
              </div>
              <div style="flex:1;margin-right: 20px;">
                <div style="color:#333333;font-size:14px;">{{$t("单价")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="item.price" style="margin-top:4px"></el-input>
              </div>
              <div style="flex:1;">
                <div style="color:#333333;font-size:14px;">{{$t("数量")}}<span
                    style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
                <el-input placeholder="" v-model="item.qty" style="margin-top:4px"></el-input>
              </div>
              <div style="position:absolute;right: 10px;top: 10px;">
                <div class="right_place" @click="reduceList(index)" style="background:red">-</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dialogObj1.val1 == 1 && !isBuy"
             style="display:flex;align-items: center;padding: 12px 0;background:#f6f8fd;border-radius:4px;margin: 0 20px;">
          <div
              style="width:2px;height:10px;background:#006bfc;border-radius:0px 6px 6px 0px;margin-right: 18px;">
          </div>
          <div style="font-weight:500;color:#333333;font-size:16px;">{{$t("运输相关信息")}}</div>
        </div>
        <div v-if="dialogObj1.val1 == 1 && !isBuy" style="padding: 20px 40px">
          <div style="display:flex">
            <div style="flex:1;margin-right: 20px;">
              <div style="color:#333333;font-size:14px;">{{$t("运输方式")}}<span
                  style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
              <el-select v-model="commodityFrom.tranmode" placeholder="" style="margin-top:4px;width: 100%;"
                         filterable>
                <el-option v-for="item in shippingMethod" :key="item.sid" :label="item.name"
                           :value="item.sid">
                </el-option>
              </el-select>
            </div>
            <div style="flex:1"></div>
          </div>
        </div>
        <div v-else
             style="display:flex;align-items: center;padding: 12px 0;background:#f6f8fd;border-radius:4px;margin: 0 20px;">
          <div
              style="width:2px;height:10px;background:#006bfc;border-radius:0px 6px 6px 0px;margin-right: 18px;">
          </div>
          <div style="font-weight:500;color:#333333;font-size:16px;">{{$t("描述信息")}}</div>
        </div>
        <div v-if="isBuy" style="padding: 20px 40px">
          <div style="display:flex;">
            <div style="flex:1;margin-right: 20px;">
              <div style="color:#333333;font-size:14px;">{{$t("质量标准")}}</div>
              <el-input placeholder="" v-model="demandFrom.quality_standard" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
            <div style="flex:1">
              <div style="color:#333333;font-size:14px;">{{$t("采购数量")}}</div>
              <el-input placeholder="" v-model="demandFrom.d_numb_des" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
          </div>
          <div style="display:flex;margin-top: 16px;">
            <div style="flex:1;margin-right: 20px;">
              <div style="color:#333333;font-size:14px;">{{$t("供应商要求")}}</div>
              <el-input placeholder="" v-model="demandFrom.supplier_require" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
            <div style="flex:1">
              <div style="color:#333333;font-size:14px;">{{$t("其他")}}</div>
              <el-input placeholder="" v-model="demandFrom.remark" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
          </div>
        </div>
        <div v-if="dialogObj1.val1 == 2 && !isBuy" style="padding: 20px 40px">
          <div style="display:flex;">
            <div style="flex:1;margin-right: 20px;">
              <div style="color:#333333;font-size:14px;">{{$t("成分与材质")}}</div>
              <el-input placeholder="" v-model="commodityFrom.g_material" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
            <div style="flex:1">
              <div style="color:#333333;font-size:14px;">{{$t("功效说明")}}</div>
              <el-input placeholder="" v-model="commodityFrom.g_effect" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
          </div>
          <div style="display:flex;margin-top: 16px;">
            <div style="flex:1;margin-right: 20px;">
              <div style="color:#333333;font-size:14px;">{{$t("使用方法")}}</div>
              <el-input placeholder="" v-model="commodityFrom.g_usemethod" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
            <div style="flex:1">
              <div style="color:#333333;font-size:14px;">{{$t("注意事项")}}</div>
              <el-input placeholder="" v-model="commodityFrom.g_remark" style="margin-top:4px"
                        type="textarea"></el-input>
            </div>
          </div>
        </div>
        <div v-if="!isBuy"
             style="display:flex;align-items: center;padding: 12px 0;background:#f6f8fd;border-radius:4px;margin: 0 20px;">
          <div
              style="width:2px;height:10px;background:#006bfc;border-radius:0px 6px 6px 0px;margin-right: 18px;">
          </div>
          <div style="font-weight:500;color:#333333;font-size:16px;">{{$t("图片信息")}}</div>
        </div>
        <div v-if="!isBuy" style="padding: 20px 40px">
          <div style="color:#333333;font-size:14px;margin-bottom: 4px;">{{$t("主图")}}<span
              style="margin-left:4px;color:rgba(232, 84, 71, 1)">*</span></div>
          <el-upload style="width:100%;" action="#" :before-upload="beforeAvatarUpload"
                     :on-remove="handleRemove" :http-request="upload" list-type="picture-card" :file-list="fileList">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="agreement_main_2"></div>
    <div class="agreement_main_3">
      <div class="agreement_main_3_place">
        <div class="agreement_main_3_place_title">{{ $t('帮助和服务') }}</div>
        <div class="agreement_main_3_place_flex">
          <div class="agreement_main_3_place_flex_1"></div>
          <div class="agreement_main_3_place_flex_2">{{ $t('帮助中心') }}</div>
          <div class="agreement_main_3_place_flex_3">{{ $t('进入') }}</div>
        </div>
        <div class="agreement_main_3_place_que">{{ $t('如何设置') }}</div>
        <div class="agreement_main_3_place_desc">{{ $t('您可以通过') }}</div>
        <div class="agreement_main_3_place_flex">
          <div class="agreement_main_3_place_flex_1"></div>
          <div class="agreement_main_3_place_flex_2">{{ $t('客服邮箱') }}</div>
        </div>
        <div class="agreement_main_3_place_desc">
          <a href="mailto:support@diit-operation-platform.com">support@diit-operation-platform.com</a>
        </div>
        <div class="agreement_main_3_place_btn">
          <a href="mailto:support@diit-operation-platform.com">{{ $t('联系我们') }}</a>
        </div>
      </div>
    </div>


    <div class="goods_foot">
      <div class="goods_foot_btn1" @click="publish">{{$t("发布")}}</div>
      <div class="goods_foot_btn2" @click="sure">{{$t("保存")}}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isBuy: false,
      demandFrom: {// 需求
        sid: '',
        demand_code: '',
        goods_name: '',
        d_classify: '',
        goods_pname: '',
        brand_id: '',
        goods_level: '',
        goods_process: '',
        source_area: '',
        source_city: '',
        spc_desc: '',
        quality_standard: '',
        d_numb_des: '',
        supplier_require: '',
        remark: '',
        d_et_des: '',
        isopen: '',
        d_category: '',
      },
      commodityFrom: {//商品
        sid: '',
        goods_id: '',
        goods_name: '',
        goods_category: '',
        name: '',
        brand_id: '',
        glevel: '',
        hmethod: '',
        sph: '',
        source_area: '',
        source_city: '',
        rough_weight: '',
        volume: '',
        pkgtype: '',
        pkgspc: '',
        price: '',
        quantity: '',
        proddate: '',
        enddate: '',
        savemethod: '',
        tranmode: '',
        d_category: '',
        imglist: [],
        spclist: [],//商品信息
        spc_desc: '',
        pc_remark: '',
        g_material: '',
        g_effect: '',
        g_usemethod: '',
        g_remark: '',
      },
      classify: [],
      origin: [],
      brand: [],
      productName: [],
      variety: [],
      unitMeasure: [],
      shippingMethod: [],
      wrapSpecification: [],
      packagingType:[],
      fileList: [],//图片
      btnType:'',

      verify:false,
      dialogObj1: {},
    }
  },
  props: ['dialogObj'],
  mounted() {
    this.dialogObj1 = this.dialogObj
    this.init()
  },
  methods: {
    reduceList(index) {
      this.commodityFrom.spclist.splice(index, 1);
    },
    add() {
      this.commodityFrom.spclist.push({
        type_name: '',
        spc: '',
        price: '',
        qty: ''
      })
    },
    //校验
    verifyFrom(from){
      let _this=this;
      this.verify = false;
      if (this.isBuy) {//买家发布需求
        if(!from.demand_code){
            this.$message.error(this.$t('必填项需求编号为空'));
            return false
        }
        if(!from.goods_name){
          this.$message.error(this.$t('必填项货物名称为空'));
          return false
        }
        if(!from.d_classify){
          this.$message.error(this.$t('必填项货物分类为空'));
          return false
        }
        if(!from.goods_pname&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项品名为空'));
          return false
        }
        if(!from.brand_id){
          this.$message.error(this.$t('必填项品种为空'));
          return false
        }
        if(!from.source_area){
          this.$message.error(this.$t('必填项产地为空'));
          return false
        }
        if(!from.source_city&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项产区为空'));
          return false
        }
      }
      if (!this.isBuy) {//商品
        if(!from.goods_id){
          this.$message.error(this.$t('必填项商品编号为空'));
          return false
        }
        if(!from.goods_name){
          if(this.dialogObj.val1==1){
            this.$message.error(this.$t('必填项货物名称为空'));
          }else {
            this.$message.error(this.$t('必填项商品名称为空'));
          }
          return false
        }
        if(!from.goods_category){
          if(this.dialogObj.val1==1){
            this.$message.error(this.$t('必填项货物分类为空'));
          }else {
            this.$message.error(this.$t('必填项商品分类为空'));
          }
          return false
        }
        if(!from.name&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项品名为空'));
          return false
        } if(!from.brand_id){
          this.$message.error(this.$t('必填项品种为空'));
          return false
        }
        if(!from.source_area){
          if(this.dialogObj.val1==1){
            this.$message.error(this.$t('必填项产地为空'));
          }else {
            this.$message.error(this.$t('必填项原产地为空'));
          }

          return false
        }
        if(!from.source_city&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项产区为空'));
          return false
        }
        if(!from.rough_weight&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项重量为空'));
          return false
        } if(!from.pkgtype&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项包装类型为空'));
          return false
        }
        if(!from.price&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项单价为空'));
          return false
        }
        if(!from.quantity&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项数量为空'));
          return false
        }
        if(!from.tranmode&&this.dialogObj.val1==1){
          this.$message.error(this.$t('必填项运输方式为空'));
          return false
        }
        console.log(from.spclist.length,this.dialogObj.val1,'this.dialogObj.val1')
        if(from.spclist.length&&this.dialogObj.val1==2){
          for(let i=0;i<from.spclist.length;i++){
            if(!from.spclist[i].type_name){
              _this.$message.error(this.$t('必填项分类名称为空'));
              return false
            }
            if(!from.spclist[i].spc){
              _this.$message.error(this.$t('必填项规格为空'));
              return false
            }
            if(!from.spclist[i].price){
              _this.$message.error(this.$t('必填项单价为空'));
              return false
            }
            if(!from.spclist[i].qty){
              _this.$message.error(this.$t('必填项数量为空'));
              return false
            }
          }
        }
        if(!this.fileList.length>0){
          this.$message.error(this.$t('必填项图片为空'));
          return false
        }


      }
      this.verify=true
    },
    //保存
    sure() {
      this.btnType=this.$t("保存成功")
      if (this.isBuy) {//买家发布需求
        if (!this.demandFrom.sid) {
          this.demandFrom.isopen = '0'
        }
        this.verifyFrom(this.demandFrom)
        if(!this.verify) return false
        this.saveDemandFrom()
      }
      if (!this.isBuy) {//卖家发布商品
        if (!this.demandFrom.sid) {
          this.commodityFrom.is_open = '0'
        }
        this.verifyFrom(this.commodityFrom)
        if(!this.verify) return false
        this.saveCommodityFrom()
      }
    },
    publish() {
      this.btnType=this.$t("发布成功")
      if (this.isBuy) {//买家发布需求
        this.demandFrom.isopen = '1'
        this.verifyFrom(this.demandFrom)
        if(!this.verify) return false
        this.saveDemandFrom()
      }
      if (!this.isBuy) {//卖家发布商品
        this.commodityFrom.is_open = '1'
        this.verifyFrom(this.commodityFrom)
        if(!this.verify) return false
        this.saveCommodityFrom()
      }
    },
    saveDemandFrom() {
      this.demandFrom.d_category = this.dialogObj1.val1
      let btn = null
      if (this.demandFrom.sid) {
        btn = '{"cmd":"DLG","name":"编辑需求","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*204;0;0"}';
      } else {
        btn = '{"cmd":"DLG","name":"新建需求","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*203;0;0"}';
      }
      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: btn,
        env: JSON.stringify(this.demandFrom)
      }, 'pdata', 'demandFrom')
    },
    saveCommodityFrom() {
      this.fileList.forEach(function (item) {
        // item['fj_name']=item['name']
        // delete item['name'];
        // item['fj_root']=item['url']
        // delete item['url'];
        item['cid'] = item['uid']
        delete item['uid'];
        delete item['url'];
        delete item['name'];
        delete item['status'];
      });
      this.commodityFrom.imglist = this.fileList
      this.commodityFrom.d_category = this.dialogObj1.val1
      let btn = null
      if (this.commodityFrom.sid) {
        btn = '{"cmd":"DLG","name":"编辑需求","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*205;0;0"}';
      } else {
        btn = '{"cmd":"DLG","name":"新增商品","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*203;0;0"}';
      }
      let data = {goods: this.commodityFrom}
      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: btn,
        env: JSON.stringify(data)
      }, 'qdata', 'commodityFrom')
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    //上传
    upload(file) {


      let formData = new FormData(); // 创建FormData对象
      formData.append('file', file.file); // 添加文件到formData

      axios({
        method: 'post',
        url: this.$store.state.host + '/entrance/sysupd?updid=33&dbid=201&fjkey=PROTOCOL&snkey=' + sessionStorage.getItem('rym_snkey'),
        data: formData,
        responseType: 'json',
        withCredentials: false,
        headers: {'Content-Type': 'multipart/form-data'} // 设置请求头为multipart/form-data类型
      })
          .then((response) => {
            if (response?.data?.data?.fj_root) {
              this.fileList.push({
                url: this.$store.state.host + '/entrance' + `/sysupd?snkey=${sessionStorage.getItem('rym_snkey')}&fjroot=${response.data.data.fj_root}&updid=36&fjname=${response.data.data.fname}`,
                name: response.data.data.fname,
                fj_root: response.data.data.fj_root,
                fj_name: response.data.data.fname,
              })
            }
            console.log(this.fileList, 'this.fileList')
          })
          .catch((error) => {
            console.error(error); // 处理错误信息
          });
    },
    //拼接返回图片
    saveFileList(imglist){
      let _this=this
      this.fileList=[]
      if(imglist){
        imglist.forEach(function (item){
          let data={}
          // if(item.fj_root){
            data.name=item.fj_name
            data.url= _this.$store.state.host + '/entrance/sysupd?snkey='+`${sessionStorage.getItem('rym_snkey')}`+'&fjroot='+item.fj_root+'&updid=36&fjname='+item.fj_name
            data.fj_root= item.fj_root,
            data. fj_name= item.fj_name,
            _this.fileList.push(data)
          // }
        })
      }
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type.indexOf('image') > -1;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error(this.$t('只能上传图片'));
      }
      if (!isLt2M) {
        this.$message.error(this.$t('2MB'));
      }
      return isJPG && isLt2M;
      // return isLt2M
    },
    getOptions(data) {
      return {
        method: 'POST',
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        data: {
          ...data,
          "snkey": sessionStorage.getItem('rym_snkey')
        },
        url: this.$store.state.host + '/entrance/sysapi',
      }
    },
    getReturn(data, val, key) {
      const res2 = axios(this.getOptions(data))

      res2.then(response => {

        if (response?.data?.data) {
          this[key] = response?.data?.data[val]
          if(key=='demandFrom'||key=='commodityFrom'){
            this.$message.success(this.btnType);
          }
          if(key=='commodityFrom'){
            this.saveFileList(response?.data?.data[val]?.imglist)
          }

        } else {
          this.$message.error( this.$t('serverAbnormal'));
          // sessionStorage.removeItem("rym_token");
          // sessionStorage.removeItem("rym_snkey");
          // sessionStorage.removeItem("rym_user");
          // sessionStorage.removeItem("rym_index");
          // this.$router.push('/')
        }
      })
    },
    back() {
      this.$emit('handBack')
    },
    chooseBack() {
      this.$emit('chooseBack')
    },
    init() {
      this.isBuy = sessionStorage.getItem('rym_is_buyer') == 'true'
      if (this.dialogObj1.sid) {
        if (this.isBuy) {
          const res2 = axios(this.getOptions({
            usercode: sessionStorage.getItem('rym_user'),
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"需求详情","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*202;0;0"}',
            env: JSON.stringify({"snkey": sessionStorage.getItem('rym_snkey'), "sid": this.dialogObj1.sid})
          }))
          res2.then(response => {
            this.demandFrom = response?.data?.data.values
            this.dialogObj1.val1 = response?.data?.data?.values.d_category
          })
        } else {
          const res2 = axios(this.getOptions({
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"商品详情","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*202;0;0"}',
            usercode: sessionStorage.getItem('rym_user'),
            env: JSON.stringify({"snkey": sessionStorage.getItem('rym_snkey'), "sid": this.dialogObj1.sid})
          }))
          res2.then(response => {
            this.commodityFrom = response?.data?.data?.values
            this.dialogObj1.val1 = response?.data?.data?.values.d_category
            console.log(response?.data?.data?.values?.imglist)
            this.saveFileList(response?.data?.data?.values?.imglist)
          })
        }
      }
      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: '{"cmd":"DLG","name":"商品分类","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*203;0;0"}',
        env: JSON.stringify({
          page: {
            currPage: 1,
            pageSize: 1000
          }
        })
      }, 'values', 'classify')

      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: '{"cmd":"DLG","name":"国家信息","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*200;0;0"}',
        env: JSON.stringify({
          page: {
            currPage: 1,
            pageSize: 1000
          }
        })
      }, 'values', 'origin')

      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: '{"cmd":"DLG","name":"计量单位","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*201;0;0"}',
        env: JSON.stringify({
          page: {
            currPage: 1,
            pageSize: 1000
          }
        })
      }, 'values', 'unitMeasure')

      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: '{"cmd":"DLG","name":"品种/品牌","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*204;0;0"}',
        env: JSON.stringify({
          page: {
            currPage: 1,
            pageSize: 1000
          }
        })
      }, 'values', 'brand')

      this.getReturn({
        usercode: sessionStorage.getItem('rym_user'),
        apiId: 'dlgclassrun',
        dbid: '01',
        btn: '{"cmd":"DLG","name":"商品品名","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*205;0;0"}',
        env: JSON.stringify({
          page: {
            currPage: 1,
            pageSize: 1000
          }
        })
      }, 'values', 'productName'),

          this.getReturn({
            usercode: sessionStorage.getItem('rym_user'),
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"品种/品牌","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*204;0;0"}',
            env: JSON.stringify({
              page: {
                currPage: 1,
                pageSize: 1000
              }
            })
          }, 'values', 'variety'),
          this.getReturn({
            usercode: sessionStorage.getItem('rym_user'),
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"运输方式","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*208;0;0"}',
            env: JSON.stringify({
              page: {
                currPage: 1,
                pageSize: 1000
              }
            })
          }, 'values', 'shippingMethod'),
          this.getReturn({
            usercode: sessionStorage.getItem('rym_user'),
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"包装规格","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*207;0;0"}',
            env: JSON.stringify({
              page: {
                currPage: 1,
                pageSize: 1000
              }
            })
          }, 'values', 'wrapSpecification'),
          this.getReturn({
            usercode: sessionStorage.getItem('rym_user'),
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"包装类型","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*206;0;0"}',
            env: JSON.stringify({
              page: {
                currPage: 1,
                pageSize: 1000
              }
            })
          }, 'values', 'packagingType')

    }
  },
  watch:{
    dialogObj: {
		  handler(newVal,oldVal) {
        this.dialogObj1 = this.dialogObj
		  },
    	immediate: true,
  		deep:true
	  }
  }
}
</script>
<style>
.goos_main_over .el-upload {
  width: 148px;
}
</style>

<style scoped>
.right_place {
  width: 16px;
  height: 16px;
  background: #006bfc;
  border-radius: 16px;
  float: right;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}

.goods_foot {
  position: fixed;
  width: calc(100% - 220px);
  bottom: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px -3px 6px rgba(194, 201, 209, 0.28);
  padding: 14px 0;
}

.goods_foot_btn2 {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid;
  border-color: #dddfe7;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
  float: right;
  margin-right: 20px;
  cursor: pointer;
}

.goods_foot_btn1 {
  cursor: pointer;
  width: 82px;
  height: 34px;
  background: #006bfc;
  border-radius: 2px;
  text-align: center;
  line-height: 34px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  float: right;
  margin-right: 20px;
}

.agreement_main {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.agreement_main_1 {
  flex: 1;
}

.agreement_main_2 {
  width: 20px;
  background: #f5f5f8;
}

.agreement_main_3 {
  width: 220px;
  background: #f5f5f8;
}

.agreement_main_3_place {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 18px;
}

.agreement_main_3_place_title {
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  margin-bottom: 33px;
}

.agreement_main_3_place_flex {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.agreement_main_3_place_flex_1 {
  width: 2px;
  height: 10px;
  background: #006bfc;
  border-radius: 0px 6px 6px 0px;
  margin-right: 6px;
}

.agreement_main_3_place_flex_2 {
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  flex: 1;
}

.agreement_main_3_place_flex_3 {
  color: #006bfc;
  font-size: 14px;
}

.agreement_main_3_place_que {
  color: #333333;
  font-size: 14px;
  margin-bottom: 6px;
}

.agreement_main_3_place_desc {
  color: #666666;
  font-size: 14px;
  margin-bottom: 18px;
}

.agreement_main_3_place_btn {
  height: 36px;
  background: #f6f8fd;
  border: 1px solid;
  border-color: #dddfe7;
  border-radius: 3px;
  text-align: center;
  line-height: 36px;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
}

.agreement_main_1_title {
  padding: 20px;
  border-bottom: 1px solid rgba(239, 239, 239, 1);
}

.show_dialog_flex_1 {
  width: 124px;
  position: relative;
  height: 40px;
  border: 1px solid;
  border-color: #dee4ed;
  border-radius: 4px;
  line-height: 40px;
  padding-left: 16px;
  background: rgba(243, 245, 249, 1);
  margin-right: 16px;
}

.goos_main_over {
  overflow-y: auto;
  height: calc(100% - 80px);
}

.goos_main_over::-webkit-scrollbar {
  display: none;
}
</style>
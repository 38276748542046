<template>
  <div class="footer">
    <div class="mb20">
      <div class="footer_line">
        <div class="footer_title">{{ $t('instruction') }}</div>
        <div class="footer_con" @click="openPDF(1)">{{ $t('btob') }}</div>
        <div class="footer_con" @click="openPDF(2)">{{ $t('warehouse') }}</div>
        <div class="footer_con" @click="openPDF(3)">{{ $t('club') }}</div>
        <div class="footer_con" @click="openPDF(4)">{{ $t('xingluo') }}</div>
      </div>
      <div class="footer_line">
        <div class="footer_title">{{ $t('help') }}</div>
        <div class="footer_con" @click="download">{{ $t('download2') }}</div>
        <div class="footer_con" @click="openkf">{{ $t('support') }}</div>
      </div>
      <div class="footer_line">
        <div class="footer_title">{{ $t('legalSupport') }}</div>
        <div class="footer_con" @click="legal($t('serviceTerms'))">{{ $t('serviceTerms') }}</div>
        <div class="footer_con" @click="legal($t('serviceTerms2'))">{{ $t('serviceTerms2') }}</div>
      </div>
    </div>
    <div class="footer_copy">{{ $t('footer') }}</div>
  </div>
</template>

<script>
export default {

  methods: {
    openPDF(index) {
      window.open(`https://www.diit-operation-platform.com/demo/doc/instruction${index}.pdf`)
    },
    download() {
      this.$alert(this.$t('soon'), '', {
        confirmButtonText: this.$t('determine'),
        showConfirmButton: false,
        callback: action => { }
      });
    },
    openkf() {
      let user_code = sessionStorage.getItem('rym_scm')
      let url = this.$store.state.host + '/entrance/TouristIndex'
      if(user_code){
        url += "?user_code="+user_code
      }
      window.open(url)
    },
    legal(name) {
      window.open(`https://www.diit-operation-platform.com/demo/doc/${name}.pdf`)
    },
  }

}
</script>
<style>
@import url(../assets/footer.css);
</style>
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        host: 'https://diit-operation-system.com',
        host1: 'http://47.104.142.67',
        sum: 0
    },
    getters: {
        bigSum(state) {
            return state.sum + 10
        }
    },
    mutations: {
        add(state, value) {
            state.sum += value
        },
        reduce(state, value) {
            state.sum -= value
        }
    },
    actions: {
        odd(context, value) {
            context.commit('add', value)
        },
    },
})
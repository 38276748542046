import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui';
import store from './store/index'
import qs from 'qs';
import i18n from './locales'
import 'element-ui/lib/theme-chalk/index.css'; // 根据自己的样式配置选择对应的CSS文件路径

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$qs = qs;

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')

<template>
    <div class="register_main">
        <Top :topIndex="0"></Top>
        <div class="pd150">
            <el-steps :active="active" finish-status="success" align-center>
                <el-step title="基本信息"></el-step>
                <el-step title="企业信息"></el-step>
                <el-step title="资金账户"></el-step>
                <el-step title="经营类目"></el-step>
            </el-steps>
            <div class="pt50">
                <div v-if="active == 0">
                    <el-form :model="ruleForm0" :rules="rules0" ref="ruleForm0" label-width="150px">
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="ruleForm0.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <el-input v-model="ruleForm0.code">
                                <template slot="append">发送验证码</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="mail">
                            <el-input v-model="ruleForm0.mail"></el-input>
                        </el-form-item>
                        <el-form-item label="设置密码" prop="password">
                            <el-input v-model="ruleForm0.password" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="再次输入密码" prop="password2">
                            <el-input v-model="ruleForm0.password2" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="国家" prop="country">
                            <el-select v-model="ruleForm0.country">
                                <el-option v-for="item in countryList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="店铺名称" prop="name">
                            <el-input v-model="ruleForm0.name"></el-input>
                        </el-form-item>
                        <el-form-item label="店铺简介" prop="introduction">
                            <el-input type="textarea" :rows="2" v-model="ruleForm0.introduction">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="店铺logo">
                            <el-upload class="upload-demo" action="#" :on-exceed="onExceed"
                                :before-upload="beforeAvatarUpload" :http-request="upload" list-type="picture-card"
                                :on-preview="handlePreview" :limit="1" :on-remove="handleRemove" :file-list="fileList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <span class="ruleForm1_desc">建议图片尺寸：40px*40px,请压缩图片在2M以内，格式为gif，jpg，png</span>
                        </el-form-item>
                        <el-form-item label="店铺详细地址" prop="address">
                            <el-input v-model="ruleForm0.address"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm0')">下一步</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="active == 1">
                    <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="150px">
                        <div class="ruleForm1_title">基础信息</div>
                        <el-form-item label="公司名称" prop="name">
                            <el-input v-model="ruleForm1.name"></el-input>
                        </el-form-item>
                        <el-form-item label="公司详细地址" prop="address">
                            <el-input v-model="ruleForm1.address"></el-input>
                        </el-form-item>
                        <el-form-item label="员工总数" prop="total">
                            <el-input v-model="ruleForm1.total" type="number" :controls="false" min="1">
                                <template slot="append">人</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="公司电话" prop="phone">
                            <el-input v-model="ruleForm1.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="注册资金" prop="capital">
                            <el-input v-model="ruleForm1.capital" type="number" :controls="false" min="1">
                                <template slot="append">万元</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="联系人姓名" prop="contacts">
                            <el-input v-model="ruleForm1.contacts"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人电话" prop="tel">
                            <el-input v-model="ruleForm1.tel"></el-input>
                        </el-form-item>
                        <el-form-item label="电商企业名称">
                            <el-input v-model="ruleForm1.enterprise"></el-input>
                        </el-form-item>
                        <el-form-item label="电商企业代码">
                            <el-input v-model="ruleForm1.code"></el-input>
                        </el-form-item>
                        <div class="ruleForm1_title">营业执照信息</div>
                        <el-form-item label="营业执照号" prop="license">
                            <el-input v-model="ruleForm1.license"></el-input>
                        </el-form-item>
                        <el-form-item label="法定经营范围" prop="introduction">
                            <el-input type="textarea" :rows="2" v-model="ruleForm1.introduction">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="营业执照电子版">
                            <el-upload class="upload-demo" action="#" :on-exceed="onExceed"
                                :before-upload="beforeAvatarUpload" :http-request="upload2" list-type="picture-card"
                                :on-preview="handlePreview2" :limit="1" :on-remove="handleRemove2" :file-list="fileList2">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <span class="ruleForm1_desc">请压缩图片在2M以内，格式为gif，jpg，png，并确保文字清晰，以免上传或审核失败</span>
                        </el-form-item>
                        <div class="ruleForm1_title">法人信息</div>
                        <el-form-item label="法人姓名" prop="legal">
                            <el-input v-model="ruleForm1.legal"></el-input>
                        </el-form-item>
                        <el-form-item label="法人证件号" prop="corporate">
                            <el-input v-model="ruleForm1.corporate"></el-input>
                        </el-form-item>
                        <el-form-item label="法人证件电子版">
                            <el-upload class="upload-demo" action="#" :on-exceed="onExceed3"
                                :before-upload="beforeAvatarUpload" :http-request="upload3" list-type="picture-card"
                                :on-preview="handlePreview3" :limit="2" :on-remove="handleRemove3" :file-list="fileList3">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <span class="ruleForm1_desc">请压缩图片在2M以内，证件正反面两张照片，确保图片清晰无缺角</span>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="previous">上一步</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm1')">下一步</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="active == 2">
                    <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="150px">
                        <el-form-item label="银行开户名" prop="name">
                            <el-input v-model="ruleForm2.name"></el-input>
                        </el-form-item>
                        <el-form-item label="银行账号" prop="bank">
                            <el-input v-model="ruleForm2.bank"></el-input>
                        </el-form-item>
                        <el-form-item label="开户银行支行名称" prop="branch">
                            <el-input v-model="ruleForm2.branch"></el-input>
                        </el-form-item>
                        <el-form-item label="支行联行号" prop="union">
                            <el-input v-model="ruleForm2.union"></el-input>
                        </el-form-item>
                        <el-form-item label="税号" prop="tax">
                            <el-input v-model="ruleForm2.tax"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="previous">上一步</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm2')">下一步</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="active == 3">
                    <div class="w500">
                        <el-checkbox-group v-model="checkedCities" >
                            <el-checkbox v-for="city in categoryList" :label="city.id" :key="city.id">
                                <div class="p10">{{ city.name }}</div>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <el-form label-width="150px">
                        <el-form-item>
                            <el-button @click="previous">上一步</el-button>
                            <el-button type="primary" @click="submit">完成</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Top from '../component/top.vue'
import axios from 'axios';
var host = 'https://www.diit-operation-platform.com:8006/bip8889'
var host_upload = 'https://www.diit-operation-platform.com:8006/rym8112'
var host_url = 'http://8.147.133.140:8080/uploads/'
export default {
    name: "Register",
    components: {
        Top,
    },
    data() {
        return {
            active: 0,
            ruleForm0: {
                phone: '',
                code: '',
                mail: '',
                password: '',
                password2: '',
                country: '',
                name: '',
                introduction: '',
                address: ''
            },
            rules0: {
                phone: [
                    { required: true, message: '请填写手机号', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请填写验证码', trigger: 'blur' }
                ],
                mail: [
                    { required: true, message: '请填写邮箱', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请填写密码', trigger: 'blur' }
                ],
                password2: [
                    { required: true, message: '请填写密码', trigger: 'blur' }
                ],
                country: [
                    { required: true, message: '请选择国家', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请填写店铺名称', trigger: 'blur' }
                ],
                introduction: [
                    { required: true, message: '请填写店铺简介', trigger: 'blur' }
                ],
            },
            countryList: [],
            fileList: [], // 店铺logo
            fileList2: [], // 营业执照电子版
            fileList3: [], // 法人证件电子版
            ruleForm1: {
                name: '',
                address: '',
                total: '',
                phone: '',
                capital: '',
                contacts: '',
                tel: '',
                enterprise: '',
                code: '',
                license: '',
                introduction: '',
                legal: '',
                corporate: ''
            },
            rules1: {
                name: [
                    { required: true, message: '请填写公司名称', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请填写公司地址', trigger: 'blur' }
                ],
                total: [
                    { required: true, message: '请填写员工总数', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请填写公司电话', trigger: 'blur' }
                ],
                capital: [
                    { required: true, message: '请填写注册资金', trigger: 'blur' }
                ],
                contacts: [
                    { required: true, message: '请填写联系人姓名', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请填写联系人电话', trigger: 'blur' }
                ],
                license: [
                    { required: true, message: '请填写营业执照号', trigger: 'blur' }
                ],
                introduction: [
                    { required: true, message: '请填写法定经营范围', trigger: 'blur' }
                ],
                legal: [
                    { required: true, message: '请填写法人姓名', trigger: 'blur' }
                ],
                corporate: [
                    { required: true, message: '请填写法人证件号', trigger: 'blur' }
                ],
            },
            ruleForm2: {
                name: '',
                bank: '',
                branch: '',
                union: '',
                tax: ''
            },
            rules2: {
                name: [
                    { required: true, message: '请填写银行开户名', trigger: 'blur' }
                ],
                bank: [
                    { required: true, message: '请填写银行账号', trigger: 'blur' }
                ],
                branch: [
                    { required: true, message: '请填写开户银行支行名称', trigger: 'blur' }
                ],
                union: [
                    { required: true, message: '请填写支行联行号', trigger: 'blur' }
                ],
                tax: [
                    { required: true, message: '请填写税号', trigger: 'blur' }
                ],
            },
            categoryList: [],
            checkedCities: []
        };
    },
    mounted() {
        this.init()
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.active++
                } else {
                    return false;
                }
            });
        },
        previous() {
            this.active--
        },
        submit() {
            this.$message.success('注册成功!');
        },
        init() {
            axios.get(host + '/store/store/getAllCountry').then(res => {
                this.countryList = res.data.result
            })

            axios.get(host + '/store/store/category').then(res => {
                this.categoryList = res.data.result
            })
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type.indexOf('image') > -1;
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('只能上传图片!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
            // return isLt2M
        },
        onExceed() {
            this.$message.error('只能上传一张图片');
        },
        //上传
        upload(file) {
            let formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 添加文件到formData

            axios({
                method: 'post',
                url: host_upload + '/upload/file', // 设置自定义上传接口地址
                data: formData,
                responseType: 'json',
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为multipart/form-data类型
            })
                .then((response) => {
                    let url = response.data.split('uploads\\')[1]
                    this.fileList = [
                        {
                            url: host_url + url,
                            name: file.file.name
                        }
                    ]
                })
                .catch((error) => {
                    console.error(error); // 处理错误信息
                });
        },
        // 删除
        handleRemove(file) {
            this.fileList = []
        },
        //查看
        handlePreview(file) {
            window.open(this.fileList[0].url)
        },
        //上传
        upload2(file) {
            let formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 添加文件到formData

            axios({
                method: 'post',
                url: host_upload + '/upload/file', // 设置自定义上传接口地址
                data: formData,
                responseType: 'json',
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为multipart/form-data类型
            })
                .then((response) => {
                    let url = response.data.split('uploads\\')[1]
                    this.fileList2 = [
                        {
                            url: host_url + url,
                            name: file.file.name
                        }
                    ]
                })
                .catch((error) => {
                    console.error(error); // 处理错误信息
                });
        },
        // 删除
        handleRemove2(file) {
            this.fileList2 = []
        },
        //查看
        handlePreview2(file) {
            window.open(this.fileList2[0].url)
        },
        //上传
        upload3(file) {
            let formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 添加文件到formData

            axios({
                method: 'post',
                url: host_upload + '/upload/file', // 设置自定义上传接口地址
                data: formData,
                responseType: 'json',
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为multipart/form-data类型
            })
                .then((response) => {
                    let url = response.data.split('uploads\\')[1]
                    this.fileList3.push({
                        url: host_url + url,
                        name: file.file.name
                    })
                })
                .catch((error) => {
                    console.error(error); // 处理错误信息
                });
        },
        // 删除
        handleRemove3(file, list) {
            this.fileList3 = list
        },
        //查看
        handlePreview3(file) {
            window.open(this.fileList3[0].url)
        },
        onExceed3() {
            this.$message.error('只上传证件正反面两张照片');
        },
    }
}
</script>

<style>@import url(../assets/register.css);</style>